 /*
 * 업무구분 : 고객홈
 * 화 면 명 : MSPCM301D
 * 화면설명 : 고객카드 수정 - 고객정보
 * 작 성 일 : 2023.02.16
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-page-container class="msp" title="고객정보" :show-title="false">
    <mo-validate-watcher ref="vWatcher">

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-info-area mb70">

        <!-- 주민등록번호 -->
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt30">
          <ur-box-container alignH="between" direction="row" class="info-title-wrap mb10">
            <span class="info-title must">
              {{ lv_CustVO301D.knbScCd === 'ZPER02'? '외국인등록번호' : '주민등록번호' }}
            </span>
            <mo-button color="normal" shape="border" size="medium" class="blue" @click="fn_MoveMSPCM050M()">고객등록동의</mo-button>
          </ur-box-container>
          <div class="fs17rem">{{ $cmUtil.insertString(lv_CustVO301D.knb, 6, '-') }}</div>
        </ur-box-container>


        <!-- 실제생일 -추가항목(2023.02.24) -->
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box">
          <div class="info-title-wrap">
            <span class="info-title">실제생일</span>
          </div>
          <div class="fex-rw mt10">
            <mo-date-picker ref="refRalDob" v-model="lv_CustRaldob" placeholder="" class="min146" :rules="validateRule" :max-date="today" underline lazy />
            <div class="ns-segment flex-1 ml20">
              <mo-segment-wrapper solid primary v-model="lv_CustVO301D.samScCd" class="chip-type-wrap">
                <mo-segment-button value="01">양력</mo-segment-button>
                <mo-segment-button value="02">음력</mo-segment-button>
              </mo-segment-wrapper>
            </div>
          </div>
        </ur-box-container>

        <!-- 직업 -->
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box">
          <div class="info-title-wrap">
            <span class="info-title must">직업</span>
          </div>
          <mo-text-field type="searchable" underline readonly class="mt10 mb0" placeholder="직업을 입력하세요" v-model="lv_CustVO301D.jobCdNm"
                        @click-icon="fn_OpenMSPCM013P" @click="fn_OpenMSPCM013P"/>
        </ur-box-container>

        <!-- 직장명 -->
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box">
          <div class="info-title-wrap">
            <span class="info-title">직장명</span>
          </div>
          <mo-text-field type="none" underline class="mt10 mb0" placeholder="직장을 입력하세요" v-model="lv_CustVO301D.jobpNm" />
        </ur-box-container>

        <!-- 휴대폰 -->
        <!-- 2023.05.10 ※ 금감원 지시사항 휴대폰 번호 변경 불가 ※ -->
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box">
          <ur-box-container alignH="between" direction="row" class="info-title-wrap mb10">
            <span class="info-title">휴대폰</span>
            <mo-button color="normal" shape="border" size="medium" class="blue" @click="fn_ApplySms('cnfm')"
                       v-if="countCMBfcerCustKnb === 'Y' && lv_RsltBasSVO.chnlCustScCd !== '01' && lv_RsltBasSVO.chnlCustScCd !== '02' && lv_RsltBasSVO.smsReciCnsntYn === 'Y' && lv_RsltBasSVO.celnoStatCo === '21'">
                       수신 변경 문자 발송
            </mo-button>
          </ur-box-container>
          <div class="verify-phone txt-left">
            <msp-bottom-select :items="phoneItems" v-model="lv_CelFronNo" ref="refCelFronNo" underline class="ns-dropdown-sheet w110px mr0" :rules="validateSelectRule" placeholder="국번" bottom-title="휴대번호" closeBtn lazy disabled/>
            <mo-decimal-field numeric ref="refCelBackNo" v-model="lv_CustVO301D.celBackNo" :rules="validateRule" mask="########" maxlength="8" underline placeholder="00000000" class="mb0 wauto"
                              @click="fn_BlockChangAlert" lazy readonly/>
          </div>
        </ur-box-container>

        <!-- 이메일 -->
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box">
          <div class="info-title-wrap">
            <span class="info-title">이메일</span>
          </div>
          <mo-text-field type="none" ref="refEmail" underline class="mt10 mb0" :rules="validateRule" placeholder="이메일을 입력하세요"
                          v-model="lv_CustVO301D.emailAddr" lazy/>
        </ur-box-container>

        <!-- 자택주소 -->
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box">
          <ur-box-container alignH="between" direction="row" class="info-title-wrap mb10">
            <span class="info-title">자택주소</span>
            <span>
              <mo-button color="normal" shape="border" size="medium" class="blue mr10" @click="fn_ApplyMnCustAddr">주고객주소 반영</mo-button>
              <mo-button color="normal" shape="border" size="medium" class="blue" @click="fn_OpenMSPCM130P('home')">
                {{ !$bizUtil.isEmpty(fn_CmptdHomeAddr) ? '수정' : '입력' }}
              </mo-button>
            </span>
          </ur-box-container>
          <div ref="type1" componentid="" class="fex-clumn" v-if="!$bizUtil.isEmpty(fn_CmptdHomeAddr)">
            <ur-box-container class="relative">
              <mo-text-field type="none" underline class="mt10 mb0" :value="fn_CmptdHomeAddr" disabled/>
              <button class="btn_clear--ty2">
                <mo-icon icon="close"  @click="fn_ClearAddr('11')"></mo-icon>
              </button>
            </ur-box-container>
            <mo-text-field type="none" underline class="mt10 mb0" :value="lv_CustVO301D.homeAddrDtal" disabled/>
          </div>
        </ur-box-container>

        <!-- 자택전화 -->
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box">
          <div class="info-title-wrap mb10">
            <span class="info-title">자택전화</span>
          </div>
          <div class="verify-phone txt-left">
            <msp-bottom-select :items="telnoItems" v-model="lv_HomTelFronNo" ref="refHomTelFronNo" underline class="ns-dropdown-sheet w200px mr0" :rules="validateSelectRule" placeholder="지역" bottom-title="지역번호" closeBtn lazy/>
            <mo-decimal-field numeric ref="refHomTelNo" v-model="lv_CustVO301D.homTelBackNo" :rules="validateRule" mask="########" maxlength="8" underline placeholder="00000000" class="mb0"
                              @focus="$refs.vWatcher.clearValidation()" lazy/>
          </div>
        </ur-box-container>

        <!-- 직장주소 -->
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box">
          <ur-box-container alignH="between" direction="row" class="info-title-wrap mb10">
            <span class="info-title">직장주소</span>
            <mo-button color="normal" shape="border" size="medium" class="blue" @click="fn_OpenMSPCM130P('job')">
              {{ !$bizUtil.isEmpty(fn_CmptdJobAddr) ? '수정' : '입력' }}
            </mo-button>
          </ur-box-container>
          <div ref="type1" componentid="" class="fex-clumn" v-if="!$bizUtil.isEmpty(fn_CmptdJobAddr)">
            <ur-box-container class="relative">
              <mo-text-field type="none" underline class="mt10 mb0" :value="fn_CmptdJobAddr" disabled/>
              <button class="btn_clear--ty2">
                <mo-icon icon="close" @click="fn_ClearAddr('12')"></mo-icon>
              </button>
            </ur-box-container>
            <mo-text-field type="none" underline class="mt10 mb0" :value="lv_CustVO301D.jobAddrDtal" disabled/>
          </div>
        </ur-box-container>

        <!-- 직장전화 -->
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box">
          <div class="info-title-wrap">
            <span class="info-title">직장전화</span>
          </div>
          <div class="verify-phone txt-left mt10">
            <msp-bottom-select :items="telnoItems" v-model="lv_JobTelFronNo" ref="refJobTelFronNo" underline class="ns-dropdown-sheet w200px mr0" :rules="validateSelectRule" placeholder="지역" bottom-title="지역번호" closeBtn lazy/>
            <mo-decimal-field numeric ref="refJobTelNo" v-model="lv_CustVO301D.jobTelBackNo" :rules="validateRule" mask="########" maxlength="8" underline placeholder="00000000" class="mb0"
                              @focus="$refs.vWatcher.clearValidation()" lazy/>
          </div>
        </ur-box-container>

        <!-- 우편물수령지 -->
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mb50">
          <span class="ns-title mb10">우편물수령지</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="lv_CustVO301D.mailRcpplScCd" class="chip-type-wrap" @input="fn_ChangeRcppl(lv_CustVO301D.mailRcpplScCd)">
              <mo-segment-button value="11">자택</mo-segment-button>
              <mo-segment-button value="12">직장</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>

        <!-- 외국인정보 -->
        <ur-box-container v-if="lv_CustVO301D.knbScCd === 'ZPER02'" alignV="start" componentid="" direction="column" class="info-box">
          <ur-box-container alignH="between" direction="row" class="info-title-wrap mb10">
            <span class="info-title">외국인정보</span>
            <mo-button color="normal" shape="border" size="medium" class="blue" @click="fn_OpenMSPCM309P">
              {{ !$bizUtil.isEmpty(lv_CustVO301D.psprtNo) && !$bizUtil.isEmpty(lv_CustVO301D.natyNm) ? '수정' : '입력' }}
            </mo-button>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-contract-list type-1">
            <mo-list-item ref="bs02" mode="none" class="closebtn">
              <ur-box-container alignV="start" direction="column" class="list-item__contents">
                <ur-box-container alignH="start" direction="column" class="list-item__contents__info">
                  <div class="fex-rw align-item-center">
                    <small class="min70 fs14rem crTy-bk7">영문명</small>
                    <span class="crTy-bk1">{{ lv_CustVO301D.custEngNm }}</span>
                  </div>
                  <div class="fex-rw align-item-center">
                    <small class="min70 fs14rem crTy-bk7">국적</small>
                    <span class="crTy-bk1">{{ lv_CustVO301D.natyNm }}</span>
                  </div>
                  <div class="fex-rw align-item-center">
                    <small class="min70 fs14rem crTy-bk7">여권번호</small>
                    <span class="crTy-bk1">{{ lv_CustVO301D.psprtNoMask }}</span>
                  </div>
                  <div class="fex-rw align-item-center">
                    <small class="min70 fs14rem crTy-bk7">거주구분</small>
                    <span class="crTy-bk1" v-if="$bizUtil.isEmpty(lv_CustVO301D.natyNm)"/>
                    <span class="crTy-bk1" v-else>
                      {{ lv_CustVO301D.dmclScCd === '01' ? '국내' : '국외' }}
                    </span>
                  </div>
                </ur-box-container>
              </ur-box-container>
            </mo-list-item>
          </ur-box-container>
        </ur-box-container>

        <!-- 취득경로 -->
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box">
          <div class="info-title-wrap">
            <span class="info-title must">취득경로</span>
          </div>
          <msp-bottom-select :items="entryPaths" v-model="lv_EntryPath" ref="refEntryPaths" underline class="ns-dropdown-sheet mb0" :disabled="lv_EntryDisabd"
                             :rules="validateSelectRule" placeholder="선택" bottom-title="취득경로" :itemValue="'key'" :itemText="'label'" closeBtn lazy/>
          <div class="fex-rw mt10" v-if="lv_EntryPath === 'intdr'">
            <mo-text-field searchable underline class="mr10 w100" placeholder="소개자 검색" v-model="lv_CustVO301D.custNm01" :rules="validateRule"
                            ref="refEntryPath"  @click="fn_OpenMSPCM129P" @click-icon="fn_OpenMSPCM129P" @keyup.enter="fn_OpenMSPCM129P" readonly lazy/>
            <mo-date-picker class="ml10 w100" v-model="lv_IntroYmd" ref="refEntryPathDate" :max-date="today" placeholder="소개일" />
          </div>
        </ur-box-container>


        <!-- 바텀시트 ::START -->
        <mo-bottom-sheet ref="BSCustSendInfo" :close-btn="false"  class="ns-bottom-sheet sheet-type-1 closebtn">
          <template v-slot:title>
            <span>{{ lv_CustVO301D.custNm }} 고객님께 아래 안내장이 발송됩니다.</span>
            <ul class="bullet-type-4 mt10">
              <li class="crTy-bk7 fs16rem fwn ls--15">본 문자는 고객안내이력이 보관되오니 고객님의 의사를 사전 확인하시고 발송하시기 바랍니다.(문자 발송 가능 시간 : 08:00 ~ 20:00)</li>
            </ul>
            <div class="ns-btn-close" @click="fn_BottomSheetHandler('BSCustSendInfo', 'close')" name="닫기"></div>
          </template>
          <div class="content-area pl0 pr0 overflow-scroll-y h300px">
            <ur-box-container alignV="start" componentid="" direction="column" class="info-box pb0">
              <div class="bgcolor-1 mt20 pt16 pb16 pl20 pr20 bd-radius-10">
                <p class="fs17rem mt0">안녕하세요.<br>삼성생명의 안내 서비스 수신 설정 (문자, 이메일, 우편)을 아래의 방법을 통해 고객님께서 직접 변경하실 수 있습니다.</p>
                <h6 class="mt20 fs17rem">① 모니모 수신설정 바로가기 :</h6>
                <a href="javascript:void(0);" class="dsD lnkTy3 bdBN" target="_blank" title="안내장 수신 설정 화면 연결">https://www.samsunglife.com/s/DIP</a>
                <h6 class="mt20 fs17rem">② 삼성생명 콜센터 : <strong>☎ 1588-3114</strong> (영업일 9:00~18:00)</h6>
                <p class="fs17rem mb0">감사합니다.</p>
              </div>
            </ur-box-container>
          </div>
          <template v-slot:action>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
              <div class="relative-div">
                <mo-button componentid="" color="primary" shape="primary" size="border" @click="fn_BottomSheetHandler('BSCustSendInfo', 'close')" class="ns-btn-round white mr5">취소</mo-button>
                <mo-button componentid="" color="primary" shape="primary" size="border" @click="fn_ApplySms('send')" class="ns-btn-round blue ml5">확인</mo-button>
              </div>
            </ur-box-container>
          </template>
        </mo-bottom-sheet>
        <!-- 바텀시트 ::END -->

      </ur-box-container>

    </mo-validate-watcher>

  </ur-page-container>
</template>

<script>
import moment from 'moment'
import Msg from '@/systems/webkit/msg/msg'
import cmConstants from '@/config/constants/cmConstants'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

import MSPCM013P from '@/ui/cm/MSPCM013P' // 보험직업조회
import MSPCM015P from '@/ui/cm/MSPCM015P' // 외국인 등록정보 
import MSPCM019P from '@/ui/cm/MSPCM019P' // 신분증진위여부
import MSPCM129P from '@/ui/cm/MSPCM129P' // 고객 조회
import MSPCM130P from '@/ui/cm/MSPCM130P' // 주소검색
import MSPCM309P from '@/ui/cm/MSPCM309P' // 외국인-본인인증

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  // 현재 화면명
  name: 'MSPCM301D', 
  // 현재 화면 ID
  screenId: 'MSPCM301D', 
  /***********************************************************************************
   * 화면 UI Property 정의
  ***********************************************************************************/
  props:{
    pCustInfo: {
      type: Object,
      default: {}
    },
    getDataCall: {
      type: Boolean,
      default: false
    }
  },
  /***********************************************************************************
   * 화면 전역 변수 선언 영역
  ***********************************************************************************/
  data () {
    return {
      title: '고객정보',
      lv_UserInfo: {}, // 사용자 정보

      validateRule: [ // Rule 체크
        () => this.chkResult || this.chkErrorMsg
      ],
      validateSelectRule: [ // Select Box Rule 체크
        () => this.chkSelectResult || this.chkSelectErrorMsg
      ],

      chkResult: true, // 핸드폰번호 유효성
      chkErrorMsg: '', // 핸드폰번호 에러 메시지

      chkSelectResult: true, // 
      chkSelectErrorMsg: '', // 

      lv_RsltBasSVO: {},    // 고객 상세인적사항 정보
      lv_RsltMnCustSVO: {}, // 주고객 상세인적사항 정보

      lv_CustRaldob: '', // 고객 실제생일
      lv_CelFronNo: '',    // 휴대전화 앞번호
      lv_HomTelFronNo: '', // 자택전화 앞번호
      lv_JobTelFronNo: '', // 직장전화 앞번호
      today: moment(new Date()).format('YYYY-MM-DD'), // 오늘 날짜
      lv_IntroYmd: '', // 소개일자
      lv_CustVO301D: {}, // 화면 바인딩용(CM301D Object)
      phoneItems: [], // 휴대전화 앞자리
      telnoItems: [], // 자택/직장전화 앞자리
      entryPaths: [], // 취득경로 선택지

      lv_EntryPath: '', //경로 (default: 기타)
      lv_EntryDisabd: false,
      ShowBottomBtn: true, // 키패드 노출 여부

      countCMBfcerCustKnb: '', // bp상태체크
    }
  },
  watch: {
    'pCustInfo.lv_RsltBasSVO'() {
      const lv_Vm = this

      this.lv_RsltBasSVO = this.pCustInfo.lv_RsltBasSVO

      if (!_.isEmpty(this.lv_RsltBasSVO)) {
        this.lv_CustVO301D = Object.assign({}, this.lv_RsltBasSVO)
        this.lv_CustRaldob = this.$bizUtil.dateDashFormat(this.lv_CustVO301D.ralDob) // 실제생일
        
        // 소개일자
        this.lv_IntroYmd = this.lv_CustVO301D.introYmd ? this.$bizUtil.dateDashFormat(this.lv_CustVO301D.introYmd) : ''
        this.fn_AfterRetrieveCustCardInfoData()

        this.fn_Init()
      }
      console.log('301D', this.lv_RsltBasSVO)
    },
    lv_CelFronNo() {
      // 자택전화 앞번호
      this.lv_CustVO301D.celFronNo = this.lv_CelFronNo
      this.$refs.vWatcher.clearValidation()
    },
    lv_HomTelFronNo() {
      // 자택전화 앞번호
      this.lv_CustVO301D.homTelFronNo = this.lv_HomTelFronNo
      this.$refs.vWatcher.clearValidation()
    },
    lv_JobTelFronNo() {
      // 직장전화 앞번호
      this.lv_CustVO301D.jobTelFronNo = this.lv_JobTelFronNo
      this.$refs.vWatcher.clearValidation()
    },
    getDataCall() {
      // 해당 탭에서 수정사항 리턴 (정보 수정 처리시 필요)
      this.fn_CallbackData()
    },
    ShowBottomBtn() {
      this.$emit('btnHandler', this.ShowBottomBtn)
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
  ***********************************************************************************/
  computed: {
    // 실제생일 포맷팅
    fn_CmptdRalDob() {
      if (this.$bizUtil.isEmpty(this.lv_RsltBasSVO)) return
      let rtnValu = ''

      if (this.lv_RsltBasSVO.ralDob !== '00000000') {
        rtnValu = this.lv_RsltBasSVO ? this.lv_RsltBasSVO.ralDob : rtnValu
      } else {
        rtnValu = this.$cmUtil.getFullBirthDate(this.lv_CustVO301D.knb, 'yyyyMMdd')
      }

      return rtnValu
    },
    // 자택주소 셋팅
    fn_CmptdHomeAddr() {
      let rtnValu = ''
      this.lv_CustVO301D.homeAddrDtal = ''

      if (!_.isEmpty(this.lv_CustVO301D) && !this.$bizUtil.isEmpty(this.lv_CustVO301D.homAddr)) {
        rtnValu = '(' + this.lv_CustVO301D.homFrnoPstcd + ') '
        rtnValu += this.lv_CustVO301D.homAddr + ' '

        if (this.lv_CustVO301D.homAddrScCd === '2') { // 신주소
          rtnValu += this.lv_CustVO301D.bldgNo01
          this.lv_CustVO301D.homeAddrDtal = this.lv_CustVO301D.homDtlad + ' '
          this.lv_CustVO301D.homeAddrDtal += this.lv_CustVO301D.adtnAddr01
        } else if (this.lv_CustVO301D.homAddrScCd === '1') { // 구조소
          this.lv_CustVO301D.homeAddrDtal = this.lv_CustVO301D.homDtlad
        }
      }

      return rtnValu
    },
    // 직장주소 셋팅
    fn_CmptdJobAddr() {
      let rtnValu = ''
      this.lv_CustVO301D.jobAddrDtal = ''

      if (!_.isEmpty(this.lv_CustVO301D) && !this.$bizUtil.isEmpty(this.lv_CustVO301D.jobpAddr)) {
        rtnValu = '(' + this.lv_CustVO301D.jobpFrnoPstcd + ') '
        rtnValu += this.lv_CustVO301D.jobpAddr + ' '

        if (this.lv_CustVO301D.jobpAddrScCd === '2') { // 신주소
          rtnValu += this.lv_CustVO301D.bldgNo02
          this.lv_CustVO301D.jobAddrDtal = this.lv_CustVO301D.jobpDtlad + ' '
          this.lv_CustVO301D.jobAddrDtal += this.lv_CustVO301D.adtnAddr02
        } else if (this.lv_CustVO301D.jobpAddrScCd === '1') { // 구조소
          this.lv_CustVO301D.jobAddrDtal = this.lv_CustVO301D.jobpDtlad
        }
      }

      return rtnValu
    }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  created() {
    console.log('■■■■■■■ MSPCM301D ■■■■■■■')
    const lv_Vm = this

    this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
    // 오늘 날짜 조회
    lv_Vm.$commonUtil.getServerTime().then(function (response) {
      lv_Vm.today = moment(response).format('YYYY-MM-DD')
    })

    // ************ 키패드 이벤트 등록 ************ //
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.KEYBOARD_SHOW, () => {
      // 키패드 노출시 처리
      lv_Vm.ShowBottomBtn = false
    })
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.KEYBOARD_HIDE, () => {
      // 키패드 숨김시 처리
      lv_Vm.ShowBottomBtn = true
    })
  },
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM301D')

    this.$refs.vWatcher.clearValidation()
  },
  destroyed() {
    // 키패드 이벤트 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.KEYBOARD_SHOW);
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.KEYBOARD_HIDE);
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /*********************************************************
     * @function fn_Init
     * @notice   화면진입시, 최초 실행 함수
     *           초기 셋팅 및 설정
    *********************************************************/
    fn_Init () {
      const lv_Vm = this
      
      // 휴대전화 앞자리 정보 세팅
      // let lv_CelphCD = lv_Vm.getStore('code').getters.getState.get('ZA_CELPH_KNB_CD')
      let defaultValu = {value: '0', text: '국번'}
      let lv_CelphCD = this.$bizUtil.cloneDeep(cmConstants.CELPH_KNB_CD)
      this.phoneItems = [defaultValu, ...lv_CelphCD]

      // 자택/직장 번호 앞자리 정보 셋팅
      // let lv_TelNoCD = lv_Vm.getStore('code').getters.getState.get('ZA_TELNO_DSTRT_CD')
      defaultValu = {value: '0', text: '지역'}
      let lv_TelNoCD = this.$bizUtil.cloneDeep(cmConstants.TELNO_DSTRT_CD)
      this.telnoItems = [defaultValu, ...lv_TelNoCD]

      /* --------------- 공통업무코드 조회 및 설정 처리 ---------------*/
      this.$commonUtil.selListEACommCd('ZA_MOBSL_CNTRY_CD')
      .then(() => {
        // 국적조회 공통코드 세팅
        let natyCdList = lv_Vm.getStore('code').getters.getState.get('ZA_MOBSL_CNTRY_CD')

        let natyObj = natyCdList.filter(item => {
            return item.key === lv_Vm.lv_CustVO301D.natyCd
          })
        
        if (!_.isEmpty(natyObj)) {
          lv_Vm.lv_CustVO301D.natyNm = natyObj[0].label
          lv_Vm.lv_CustVO301D.bpNatyNm = natyObj[0].label
        }

        // 고객정보 세팅
        lv_Vm.fn_SetCustVO()
      });

    },
    /*********************************************************
     * @function fn_SetCustVO
     * @notice   고객 정보 셋팅
    *********************************************************/
    fn_SetCustVO(){
      const lv_Vm = this

      // 실제생일 (필요시)
      let birthDay = this.$cmUtil.getFullBirthDate(this.lv_CustVO301D.knb, 'yyyy-MM-dd')
      if (this.lv_CustVO301D.ralDob !== '00000000') {
        // this.lv_CustVO301D.ralDob = this.lv_CustVO301D.ralDob ? this.lv_RsltBasSVO.ralDob : birthDay

        // 실제생일 양력/음력 (default: 양력)
        this.lv_CustVO301D.samScCd = !this.$bizUtil.isEmpty(this.lv_CustVO301D.samScCd) ? this.lv_CustVO301D.samScCd : ''
        // this.lv_CustVO301D.samScCdNm = this.lv_CustVO301D.samScCd === '01' ? '양력' : '음력'
      } else {
        // this.lv_CustVO301D.ralDob = birthDay
        this.lv_CustVO301D.samScCd = ' '
      }

      // 직장명
      this.lv_CustVO301D.jobpNm = this.lv_CustVO301D.jobpNm ? this.lv_CustVO301D.jobpNm.trim() : ''

      // 이메일
      this.lv_CustVO301D.emailAddr = this.lv_CustVO301D.emailAddr ? this.lv_CustVO301D.emailAddr.trim() : ''

      // 휴대폰 번호
      let celNoObj = this.lv_CustVO301D.celno.split('-')
      if (celNoObj.length > 2) {
        this.lv_CustVO301D.celBackNo = celNoObj[1] + celNoObj[2]

        let findCelNo = this.phoneItems.filter(item => {
              return item.value === celNoObj[0]
          });

        if (!_.isEmpty(findCelNo)) {
          // 휴대폰번호 앞자리 셋팅
          lv_Vm.fn_CelnoFrno = findCelNo[0].value
        } else {
          lv_Vm.fn_CelnoFrno = ''
        }
      }

      // 취득경로
      this.entryPaths = cmConstants.ENTRY_PATH_CD
      if (this.lv_CustVO301D.coalCustYn === 'Y') { // 제휴 고객인 경우에는 취득경로 선택불가하게 처리
        this.entryPaths.push({key: 'coalCustYn', label: '제휴'})
        this.lv_EntryPath = 'coalCustYn'
        this.lv_EntryDisabd = true // 취득경로를 disabled함.
      } else {
        if (this.lv_CustVO301D.acutnCustYn === 'Y') { // 지인
          this.lv_EntryPath = 'acutnCustYn'
        } else if (this.lv_CustVO301D.aptCustYn === 'Y') { // 개척
          this.lv_EntryPath = 'aptCustYn'
        } else if (this.lv_CustVO301D.rspnDstrtCustYn === 'Y') { // 기타
          this.lv_EntryPath = 'rspnDstrtCustYn'
        } else if (this.lv_CustVO301D.intdrChnlCustId.trim() !== '') { // 소개
          this.lv_EntryPath = 'intdr'
        }
      }

      // 휴대전화
      celNoObj = this.lv_CustVO301D.celno.split('-')

      // 2023.05.10 ※ 금감원 지시사항 휴대폰 번호 변경 불가 ※
      this.phoneItems.push({ text: celNoObj[0], value: celNoObj[0] })
      this.lv_CelFronNo = celNoObj[0]
      this.lv_CustVO301D.celFronNo = this.lv_CelFronNo;

      if (celNoObj.length > 2) {
        // 지역번호 선택값 유무 체크
        // let chkCelNo = lv_Vm.phoneItems.filter(item => {
        //     return item.value === celNoObj[0]
        //   });

        // if(!_.isEmpty(chkCelNo)) this.lv_CelFronNo = celNoObj[0]
       
        this.lv_CustVO301D.celBackNo = celNoObj[1] + celNoObj[2]
      }
      
      // 자택전화
      celNoObj = this.lv_CustVO301D.homTelno.split('-')
      if (celNoObj.length > 2) {
        // 지역번호 선택값 유무 체크
        let chkHomTel = lv_Vm.telnoItems.filter(item => {
            return item.value === celNoObj[0]
          });

        if(!_.isEmpty(chkHomTel)) {
          this.lv_HomTelFronNo = celNoObj[0]
          this.lv_CustVO301D.homTelFronNo = this.lv_HomTelFronNo
        }
       
        this.lv_CustVO301D.homTelBackNo = celNoObj[1] + celNoObj[2]
      }
      // 직장전화
      celNoObj = this.lv_CustVO301D.jobpTelno.split('-')
      if (celNoObj.length > 2) {
        let chkJobTel = lv_Vm.telnoItems.filter(item => {
            return item.value === celNoObj[0]
          });
        
        if(!_.isEmpty(chkJobTel)) {
          this.lv_JobTelFronNo = celNoObj[0]
          this.lv_CustVO301D.jobTelFronNo = this.lv_JobTelFronNo;
        }

        this.lv_CustVO301D.jobTelBackNo = celNoObj[1] + celNoObj[2]
      }

      // 소개자
      this.lv_CustVO301D.custNm01 = !this.$bizUtil.isEmpty(this.lv_CustVO301D.custNm01) ? this.lv_CustVO301D.custNm01.trim() : ''

      this.lv_CustVO301D.knbFrno = this.lv_CustVO301D.knb ? this.lv_CustVO301D.knb.substr(0,6) : ''
      this.lv_CustVO301D.knbBkFstNo = this.lv_CustVO301D.knb ? this.lv_CustVO301D.knb.substr(6,1) : ''

      this.lv_CustVO301D.psprtNoMask = ''
      if (!this.$bizUtil.isEmpty(this.lv_CustVO301D.psprtNo)) {
        let psprtNoMask = '*****' + this.lv_CustVO301D.psprtNo.substr(-5)
        this.lv_CustVO301D.psprtNoMask = psprtNoMask
      }
    },
    /*********************************************************
     * @function fn_BtnCntryCode
     * @notice   국가코드/여권 버튼 클릭 시 호출
    *********************************************************/
    fn_BtnCntryCode () {
      if (this.$bizUtil.isEmpty(this.lv_CustVO301D.prextKnb)) return

      this.lv_CustVO301D.isExistCustYN = 'Y'
      
      let foreignPartnerNo = this.lv_CustVO301D.foreignPartnerNo
      if (!this.$bizUtil.isEmpty(foreignPartnerNo) && foreignPartnerNo === 'E999999999') {
        let contentMsg = this.$t('cm')['ECMC078'] // 해당고객은 거래종료, 비정상, 다건 조회 등의 사유로 조회가 불가 합니다.

        this.alertProps = {single: true, title: "", content: contentMsg}
        this.fn_ShowBottomAlert()
      } else {
        window.setTimeout(() => {
          this.fn_OpenMSPCM019P()
        }, 500);
        
      }
    },
    /*********************************************************
     * @function fn_ExistCustSearch
     * @notice   기존고객 확인 조회
    *********************************************************/
    fn_ExistCustSearch () {
      const lv_Vm = this

      let pParams = {
                      prcsfCd: '01',
                      custNm: this.lv_CustVO301D.custNm, // 고객명
                      cnsltNo: this.lv_CustVO301D.cnsltNo, // 컨설턴트 번호
                      celno: this.lv_CustVO301D.celno, // 핸드폰번호 ('-'하이픈포함)
                      infoPrcusCnsntTypCd: this.lv_CustVO301D.infoPrcusCnsntTypCd, // 정보활용동의코드
                      chnlCustId: this.lv_CustVO301D.chnlCustId // 기존고객- 채널고객ID (Agent파일ID)
                    }

      // const trnstId = 'txTSSCM13S1'
      const trnstId = 'txTSSCM13S2'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          if (!_.isEmpty(res.body)) {
            let custInfoVO = res.body.iCHoshInfoListInqrVO[0] // 조회하려는 고객 정보 (0번째가 조회 고객)

            if (!_.isEmpty(custInfoVO)) {
              lv_Vm.lv_CustVO301D.prextKnb = custInfoVO.prextKnb

              lv_Vm.fn_BtnCntryCode()
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', res.msgComm.msgDesc)
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', res.msgComm.msgDesc)
          }
        })
        .catch(error => {
          window.vue.error(error)
        })
        
    },
    /***************************************************************
     * @function fn_SetPopup
     * @name     팝업_Props_셋팅
     * @param    popId :호출할 팝업ID
     * @param    fn_Callback :CallbackFunction
     * @notice   파라미터, Callback 함수 셋팅
    ***************************************************************/
    fn_SetPopup (popId, fn_Callback) {
      const lv_Vm = this
      let popupObj = {}
      
      switch (popId) {
        case 'MSPCM013P': 
          popupObj.comp = MSPCM013P
          popupObj.properties = { pJobCdNm : lv_Vm.lv_CustVO301D.jobCdNm } // 직업코드명
          break;
        case 'MSPCM015P': // 외국인 등록정보 
          popupObj.comp = MSPCM015P
          popupObj.properties = { tempCustCardVo : lv_Vm.lv_CustVO301D } // 고객정보VO
          break;
        case 'MSPCM019P': // 신분증진위여부
          popupObj.comp = MSPCM019P
          popupObj.properties = { tempCustCardVo: lv_Vm.lv_CustVO301D } // 고객정보VO
          break;
        case 'MSPCM129P': // 고객 조회
          popupObj.comp = MSPCM129P
          popupObj.properties = { pCustNm: lv_Vm.lv_CustVO301D.custNm01 } // 소개자명
          popupObj.noHeader = true
          break;
        case 'MSPCM130P': // 보험직업조회
          popupObj.comp = MSPCM130P
          popupObj.properties = {}
          break;
        case 'MSPCM309P': // 외국인-본인인증
          popupObj.comp = MSPCM309P
          popupObj.properties = { tempCustCardVo : lv_Vm.lv_CustVO301D } // 고객정보VO
          popupObj.noHeader = true
          break;
      }

      this.fn_OpenPopup(popupObj, fn_Callback)
    },
    /***************************************************************
     * @function fn_OpenPopup
     * @name     팝업_호출
     * @notice   Util처럼 선언한 함수
     * @param    fn_Callback :CallbackFunction
    ***************************************************************/
    fn_OpenPopup (popupObj, fn_Callback) {
      let lv_Popup = this.$bottomModal.open(popupObj.comp,{
        properties: popupObj.properties,
        listeners: {
          // 기개발된 팝업 emit event name이 일관되지 않아, 모두 등록하였습니다.
          confirmPopup: (pData) => {
            this.$bottomModal.close(lv_Popup)

            if (typeof(fn_Callback) === 'function') {
              fn_Callback(pData)
            }
          },
          onPopupClose: (pData) => {
            this.$bottomModal.close(lv_Popup)

            if (typeof(fn_Callback) === 'function') {
              fn_Callback(pData)
            }
          },
          onPopupConfirm: (pData) => {
            this.$bottomModal.close(lv_Popup)

            if (typeof(fn_Callback) === 'function') {
              fn_Callback(pData)
            }
          },
          onPopupCancel: (pData) => {
            this.$bottomModal.close(lv_Popup)
            
            if (typeof(fn_Callback) === 'function') {
              fn_Callback(pData)
            }
          },
          cancelPopup: (pData) => {
            this.$bottomModal.close(lv_Popup)

            if (typeof(fn_Callback) === 'function') {
              fn_Callback(pData)
            }
          }
        }
      },
      {
        properties: { noHeader: popupObj.noHeader ? true : false }
      });

    },

    /****************************************************************************
     * 팝업 호출 Function 영역 ::START
     * @param ('popupID', CallbackFunction)
    ****************************************************************************/
    /******************************************
     * @name 보험직업_검색
    ******************************************/
    fn_OpenMSPCM013P () {
      this.fn_SetPopup('MSPCM013P', this.fn_ConfirmMSPCM013P)
    },
    /******************************************
     * @name 외국인_정보_입력
    ******************************************/
    fn_OpenMSPCM015P () {
      this.fn_SetPopup('MSPCM015P', this.fn_ConfirmMSPCM015P)
    },
    /******************************************
     * @name 외국인_신분증_진위_확인
    ******************************************/
    fn_OpenMSPCM019P () {
      this.fn_SetPopup('MSPCM019P', this.fn_ConfirmMSPCM019P)
    },
    /******************************************
     * @name 소개자_고객조회
    ******************************************/
    fn_OpenMSPCM129P () {
      this.fn_SetPopup('MSPCM129P', this.fn_ConfirmMSPCM129P)
    },
    /******************************************
     * @name 주소검색
    ******************************************/
    fn_OpenMSPCM130P (addrType) {
      this.lv_EditAddrType = addrType
      this.fn_SetPopup('MSPCM130P', this.fn_ConfirmMSPCM130P)
    },
    /******************************************
     * @name 외국인-본인인증
    ******************************************/
    fn_OpenMSPCM309P () {
      this.fn_SetPopup('MSPCM309P', this.fn_ConfirmMSPCM309P)
    },
    /****************************************************************************
     * 팝업 CallBack Function 영역 ::START                                      *
    ****************************************************************************/
    /******************************************
     * @name 보험직업_검색
    ******************************************/
    fn_ConfirmMSPCM013P(pData) {
      if (!_.isEmpty(pData)) {
        this.lv_CustVO301D.jobCd = pData.jobCd
        this.lv_CustVO301D.jobCdNm = pData.jobCdNm

        // 직업 변경시, 직장명 초기화 2023.04.21 황고은 선임 요청(테블릿과 동일하게)
        // this.lv_CustVO301D.jobpNm = ''
      }
    },
    /******************************************
     * @name 외국인_정보_입력
    ******************************************/
    fn_ConfirmMSPCM015P (pData) {
      // 외국인 정보 변경 여부
      this.lv_CustVO301D.isChangeForigInfo = false

      if (!_.isEmpty(pData)) {
        console.log(pData)
        this.lv_CustVO301D.custEngNm = pData.custEngNm
        this.lv_CustVO301D.bpCustEngNm = pData.custEngNm

        this.lv_CustVO301D.dmclScCd = pData.dmclScCd
        this.lv_CustVO301D.bpDmclScCd = pData.dmclScCd

       
        this.lv_CustVO301D.dmclScCdNm =  pData.bpDmclScCdNm === '01' ? '국내' : '해외'
        this.lv_CustVO301D.bpDmclScCdNm = pData.bpDmclScCdNm === '01' ? '국내' : '해외'

        this.lv_CustVO301D.natyNm = pData.natyNm
        this.lv_CustVO301D.bpNatyNm = pData.natyNm

        this.lv_CustVO301D.natyCd = pData.natyCd
        this.lv_CustVO301D.bpNatyCd = pData.natyCd

        this.lv_CustVO301D.psprtNo = pData.psprtNo
        this.lv_CustVO301D.bpPsprtNo = pData.psprtNo

        this.lv_CustVO301D.psprtNoMask = ''
        if (!this.$bizUtil.isEmpty(this.lv_CustVO301D.psprtNo)) {
          let psprtNoMask = '*****' + this.lv_CustVO301D.psprtNo.substr(-5)
          this.lv_CustVO301D.psprtNoMask = psprtNoMask
        }
        
        this.lv_CustVO301D.isChangeForigInfo = true
      }

    },
    /******************************************
     * @name 외국인_신분증_진위_확인
    ******************************************/
    fn_ConfirmMSPCM019P () {
      const lv_Vm = this

      // 외국인고객 스크래핑 여부
      lv_Vm.lv_CustVO301D.scrapYn = 'Y'

      //MSPCM015P 호출
      setTimeout(function() {
        lv_Vm.fn_OpenMSPCM015P()
      }, '500');
    },
    /******************************************
     * @name 소개자_고객조회
    ******************************************/
    fn_ConfirmMSPCM129P (param) {
      let toastMsg = ''

      if (!_.isEmpty(param) && param !== 'removeLastPopup') { // 확인 버튼 선택 시, 파라미터가 넘어옴.
        this.lv_CustVO301D.custNm01 = param.custNm // 고객명
        this.lv_CustVO301D.intdrChnlCustId = param.chnlCustId // 채널 고객ID
        this.lv_CustVO301D.custNm01Prev = this.lv_CustVO301D.custNm01 // 확정된 소개자 명 원본이름을 보관

        toastMsg = '소개자 입력이 완료되었습니다.'
        this.$addSnackbar(toastMsg)
      } else { // 취소 버튼 선택 시, 값이 없음.
        // 기존에 입력되어 있는 소개자가 없는 경우에만 소개자 항목 초기화 함.
        if (this.lv_CustVO301D.intdrChnlCustId === null || this.lv_CustVO301D.intdrChnlCustId === '') {
          this.lv_CustVO301D.custNm01 = '' // 고객명
          this.lv_CustVO301D.intdrChnlCustId = '' // 채널 고객ID

        toastMsg = '소개자 입력이 되지 않았습니다.'
        this.$addSnackbar(toastMsg)
        }
      }
    },
    /******************************************
     * @name 주소검색
    ******************************************/
    fn_ConfirmMSPCM130P (pData) {
      if(_.isEmpty(pData)) return

      if (this.lv_EditAddrType === 'home') { // 자택주소
        this.lv_CustVO301D.homAddrScCd = pData.addrScCd
        this.lv_CustVO301D.homFrnoPstcd = pData.pstCd
        this.lv_CustVO301D.homAddr = pData.basAddr

        this.lv_CustVO301D.homAddrRefnCd = pData.addrRefnCd // 주소정제 코드

        this.lv_CustVO301D.bldgNo01 = pData.bldgBonbHo
        this.lv_CustVO301D.homDtlad = pData.roadNmDtlad
        this.lv_CustVO301D.adtnAddr01 = pData.roadNmAddmAddr

      } else if (this.lv_EditAddrType === 'job') { // 직장주소
        this.lv_CustVO301D.jobpAddrScCd = pData.addrScCd
        this.lv_CustVO301D.jobpFrnoPstcd = pData.pstCd
        this.lv_CustVO301D.jobpAddr = pData.basAddr

        this.lv_CustVO301D.jobpAddrRefnCd = pData.addrRefnCd // 주소정제 코드

        this.lv_CustVO301D.bldgNo02 = pData.bldgBonbHo
        this.lv_CustVO301D.jobpDtlad = pData.roadNmDtlad
        this.lv_CustVO301D.adtnAddr02 = pData.roadNmAddmAddr
      }
    },
    /******************************************
     * @name 외국인-본인인증
    ******************************************/
    fn_ConfirmMSPCM309P (pData) {
      if (pData === 'S') { // 본인인증 완료했을때
        this.fn_ExistCustSearch()
      } else {
        this.$addSnackbar('본인인증을 취소하셨습니다.')
      }
    },
    /****************************************************************************
     * 팝업 CallBack Function 영역 ::END                                        *
    ****************************************************************************/

    /***************************************************************
     * @function fn_CallbackData
     * @notice   부모 화면(MSPCM300P)에 세대우 변경사항 리턴한다
    ***************************************************************/
    fn_CallbackData() {
      // 실제 생일
      if (!this.$bizUtil.isEmpty(this.lv_CustRaldob)) {
        this.lv_CustVO301D.ralDob = this.lv_CustRaldob.split('-').join('')
      }
      // 주민번호 암호화
      this.lv_CustVO301D.knbBknoCipher = this.lv_CustVO301D.knbBkFstNo + '******'

      // 직장명
      this.lv_CustVO301D.jobpNm = this.lv_CustVO301D.jobpNm ? this.lv_CustVO301D.jobpNm.trim() : ''

      // 이메일
      this.lv_CustVO301D.emailAddr = this.lv_CustVO301D.emailAddr ? this.lv_CustVO301D.emailAddr.trim() : ''
      this.lv_CustVO301D.emailStatFlag = "X"

      // 자택전화
      let celBackNo = this.lv_CustVO301D.homTelBackNo ? this.lv_CustVO301D.homTelBackNo.trim() : ''
      let celLastNo = celBackNo ? celBackNo.substr(-4) : ''
      let celmidNo = celBackNo ? celBackNo.substr(0, celBackNo.length-4) : ''
      this.lv_CustVO301D.homTelno = celBackNo ? this.lv_HomTelFronNo + '-' + celmidNo + '-' + celLastNo : ''

      // 직장전화
      celBackNo = this.lv_CustVO301D.jobTelBackNo ? this.lv_CustVO301D.jobTelBackNo.trim() : ''
      celLastNo = celBackNo ? celBackNo.substr(-4) : ''
      celmidNo = celBackNo ? celBackNo.substr(0, celBackNo.length-4) : ''
      this.lv_CustVO301D.jobpTelno = celBackNo ? this.lv_JobTelFronNo + '-' + celmidNo + '-' + celLastNo : ''

      // 취득경로
      this.fn_SetEntryPath()
      this.lv_CustVO301D.entryPath = this.lv_EntryPath

      this.$emit('setCM301D', this.lv_CustVO301D)
    },
    /***************************************************************
     * @function fn_SetEntryPath
     * @notice   취득경로 데이터 셋팅
    ***************************************************************/
    fn_SetEntryPath () {
      this.lv_CustVO301D.coalCustYn = 'N' // 개척
      this.lv_CustVO301D.acutnCustYn = 'N' // 지인
      this.lv_CustVO301D.aptCustYn = 'N' // 개척
      this.lv_CustVO301D.rspnDstrtCustYn = 'N' // 기타

      if (this.lv_EntryPath !== 'intdr') {
        this.lv_CustVO301D.intdrChnlCustId = '' // 소개자 채널고객ID
        this.lv_CustVO301D.custNm01 = '' // 소개자
      }

      switch (this.lv_EntryPath) {
        case 'coalCustYn': // 제휴
          this.lv_CustVO301D.coalCustYn = 'Y'
          break;
        case 'acutnCustYn': // 지인
          this.lv_CustVO301D.acutnCustYn = 'Y'
          break;
        case 'aptCustYn': // 개척
          this.lv_CustVO301D.aptCustYn = 'Y'
          break;
        case 'rspnDstrtCustYn': // 기타
          this.lv_CustVO301D.rspnDstrtCustYn = 'Y'
          break;
        case 'intdr': // 소개
          // 소개자(custNm01)와 소개일(introYmd)
          this.lv_CustVO301D.introYmd = this.lv_IntroYmd ? this.lv_IntroYmd.split('-').join('') : ''
          break;
      }

      this.lv_CustVO301D.entryPath = this.lv_EntryPath
    },
    /***************************************************************
     * @function fn_SendMessage
     * @name     안내문자_발송
     * @notice   안내 문자 발송 서비스 실행
    ***************************************************************/
    fn_SendMessage () {
      const lv_Vm = this

      let t_LmsDspchCntnt = '안녕하세요.\n' + '삼성생명의 안내 서비스 수신 설정 (문자, 이메일, 우편)을 아래의 방법을 통해 고객님께서 직접 변경하실 수 있습니다.\n\n' +
                            '① 모니모 수신설정 바로가기 :\n' + 'https://www.samsunglife.com/s/DIP\n\n' +
                            '② 삼성생명 콜센터 : ☎ 1588-3114 (영업일 9:00~18:00)\n\n' +
                            '감사합니다.'


      let pParams = {
        lmsTitlNm: '안내장 수신동의 변경 방법 안내(알림톡+LMS)', // 안내장 타이틀
        lmsDspchCntnt: t_LmsDspchCntnt, // 안내장 내용
        gudnlCd: '00106',

        custNm: lv_Vm.lv_CustVO301D.custNm, // 고객명
        custId: lv_Vm.lv_CustVO301D.custId, // 고객ID
        knbFrno: lv_Vm.lv_CustVO301D.knb.substring(0, 6), // 생년월일
        knbBknoCipher: lv_Vm.lv_CustVO301D.knbBknoCipher, // 주민번호 암호화
        celno: lv_Vm.lv_CustVO301D.celno, // 휴대번호

        smsSendrEno: this.lv_UserInfo.userId, // 사용자 사번
        chrgeDeptOrgNo: this.lv_UserInfo.onpstDofOrgNo, // 사용자 지역단 번호
        trtFofOrgNo: this.lv_UserInfo.onpstFofOrgNo // 사용자 지점 번호
      }

      const trnstId = 'txTSSCM11U2'
      const auth = 'U'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          if (res.body !== null && res.body !== '') {
            if (res.body.sucsYn === 'Y') {
              if (lv_Vm.lv_IsSent) {
                lv_Vm.getStore('toast').dispatch('ADD', '문자발송 재요청이 완료되었습니다.')
              } else {
                lv_Vm.getStore('toast').dispatch('ADD', '문자발송 요청이 완료되었습니다.')
                lv_Vm.lv_IsSent = true
              }
            } else {
              lv_Vm.getStore('toast').dispatch('ADD', '문자발송 요청이 실패하였습니다.')
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', res.msgComm.msgDesc)
          }
        })
        .catch(error => {
          window.vue.error(error)
        });

    },
    /***************************************************************
     * @function fn_BottomAlert
     * @name     BottomAlert_Component_호출_함수
     * @notice   바텀시트 알럿
    ***************************************************************/
    fn_BottomAlert(contentMsg) {
      let alertObj = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          content: contentMsg
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(alertObj)
          }
        }
      });

    },
    /***************************************************************
     * @function fn_ApplyMnCustAddr
     * @name     주고객_주소_반영_이벤트
     * @notice   주고객 정보 조회 함수 호출
    ***************************************************************/
    fn_ApplyMnCustAddr() {
      let _mnCustRltnCd = this.lv_RsltBasSVO.mnCustRltnCd
      if (_mnCustRltnCd === '11' || _mnCustRltnCd === '01') { // 본인일 경우
        let alertMsg = '주고객이 본인입니다.'
        this.fn_BottomAlert(alertMsg)
      } else {
        this.fn_RetrieveMnCustCardInfoData()
      }
    },
    /***************************************************************
     * @function fn_RetrieveMnCustCardInfoData
     * @name     주고객_정보_조회
     * @notice   주고객의 주소로 반영한다.
    ***************************************************************/
    fn_RetrieveMnCustCardInfoData() {
      const lv_Vm = this

      let pParams = { 
        chnlCustId: this.lv_CustVO301D.mnCustChnlCustId, // 주고객채널ID
        cnsltNo: this.lv_CustVO301D.cnsltNo // 컨설턴트 사번
      }

      const trnstId = 'txTSSCM11S1'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          if (!_.isEmpty(res.body)) {
            console.log(res.body)
            lv_Vm.lv_RsltMnCustSVO = res.body
            lv_Vm.fn_ApplyMnCustAddrPostHandler()
          } else {
            let alertMsg = '삭제된 고객이거나 해당 고객카드 정보가 없습니다.'
            lv_Vm.fn_BottomAlert(alertMsg)
          }
        })
        .catch(error => {
          window.vue.error(error)
        });

    },
    /**************************************************************************************
     * @function fn_AfterRetrieveCustCardInfoData
     * @notice   고객카드 조회 후 설정작업 ( 2019.09.17 기존 fn_Init() 함수에서 분리함)
    **************************************************************************************/
    fn_AfterRetrieveCustCardInfoData () {
      const lv_Vm = this

      let pParams = {
        knbFrno: this.lv_RsltBasSVO.knb.substring(0, 6), // 주민번호 앞번호
        knbBknoCipher: this.lv_RsltBasSVO.knbBknoCipher, // 주민번호 뒷번호
        knbBkno: '', // 주민번호 뒷번호 평문
      }

      const trnstId = 'txTSSCM11S8'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          if (!_.isEmpty(res.body)) {
            if (!_.isEmpty(res.body.cMBfcerCustKnbVO)) {
              let tcrmExpnNm = res.body.cMBfcerCustKnbVO

              lv_Vm.countCMBfcerCustKnb = ''

              tcrmExpnNm.forEach(value => {
                if (value.custStatCd === '10' && value.sprtnDestStatCd === '10') {
                  lv_Vm.countCMBfcerCustKnb = 'Y'
                }
              })

            }
          } else {
            // 에러 메시지 발생
            lv_Vm.getStore('confirm').dispatch('ADD', res.msgComm.msgDesc)
          }
        })
        .catch(error => {
          window.vue.error(error)
        })

    },
    /***************************************************************
     * @function fn_ApplyMnCustAddrPostHandler
     * @name     주고객_주소_셋팅
     * @notice   주고객의 주소로 데이터 셋팅
    ***************************************************************/
    fn_ApplyMnCustAddrPostHandler () {
      // 자택주소
      this.lv_CustVO301D.homAddrScCd = this.lv_RsltMnCustSVO.homAddrScCd // [주고객] 자택주소구분코드 (1:지번주소, 2:도로명주소,3:국외주소)
      this.lv_CustVO301D.homAddr = this.lv_RsltMnCustSVO.homAddr // [주고객] 자택주소
      this.lv_CustVO301D.homDtlad = this.lv_RsltMnCustSVO.homDtlad // [주고객] 자택상세주소
      this.lv_CustVO301D.bldgNo01 = this.lv_RsltMnCustSVO.bldgNo01 // [주고객] 자택건물번호
      this.lv_CustVO301D.adtnAddr01 = this.lv_RsltMnCustSVO.adtnAddr01 // [주고객] 자택부가주소
      this.lv_CustVO301D.homFrnoPstcd = this.lv_RsltMnCustSVO.homFrnoPstcd // [주고객] 자택우편번호앞자리
      this.lv_CustVO301D.homAddrRefnCd = this.lv_RsltMnCustSVO.homAddrRefnCd // [주고객] 자택주소정제코드 (01: 정제주소, 02:입력주소)
      this.lv_CustVO301D.homAddrStatCd = this.lv_RsltMnCustSVO.homAddrStatCd // [주고객] 자택주소상태코드 (11:정상, 12:오류, 13:반송(생명특화))

      // 직장주소
      this.lv_CustVO301D.jobpAddrScCd = this.lv_RsltMnCustSVO.jobpAddrScCd // [주고객] 직장주소구분코드 (1:지번주소, 2:도로명주소,3:국외주소)
      this.lv_CustVO301D.jobpAddr = this.lv_RsltMnCustSVO.jobpAddr // [주고객] 직장주소
      this.lv_CustVO301D.jobpDtlad = this.lv_RsltMnCustSVO.jobpDtlad // [주고객] 직장상세주소
      this.lv_CustVO301D.bldgNo02 = this.lv_RsltMnCustSVO.bldgNo02 // [주고객] 직장건물번호
      this.lv_CustVO301D.adtnAddr02 = this.lv_RsltMnCustSVO.adtnAddr02 // [주고객] 직장부가주소
      this.lv_CustVO301D.jobpFrnoPstcd = this.lv_RsltMnCustSVO.jobpFrnoPstcd // [주고객] 직장우편번호앞자리
      this.lv_CustVO301D.jobpAddrRefnCd = this.lv_RsltMnCustSVO.jobpAddrRefnCd // [주고객] 직장주소정제코드 (01: 정제주소, 02:입력주소)
      this.lv_CustVO301D.jobpAddrStatCd = this.lv_RsltMnCustSVO.jobpAddrStatCd // [주고객] 직장주소상태코드 (11:정상, 12:오류, 13:반송(생명특화))
    },
    /***************************************************************
     * @function fn_ApplySms
     * @notice   수신 변경 문자 발송 클릭 이벤트
     * @param    falg send: 문자 발송/cnfm: 확인 알럿
    ***************************************************************/
    fn_ApplySms (falg) {
      const lv_Vm = this

      let alertMsg = ''

      if (falg === 'send') {
        alertMsg = '문자 발송 가능 시간이 아닙니다.'
      } else {
        alertMsg = '고객님께 안내 문자를 발송할 수 있는 시간은<br>08:00 ~ 20:00 입니다.'
      }

      this.$commonUtil.getServerTime()
        .then(res => {
        let chkTm = moment(res).format('HH')

        // 20시 이후 SMS 발송 중지 처리
        if ( Number(chkTm) >= 20 || Number(chkTm) < 8) {
          lv_Vm.fn_BottomAlert(alertMsg)
        } else {
          if (falg === 'send') {
            lv_Vm.fn_SendMessage()
          } else {
            lv_Vm.fn_BottomSheetHandler('BSCustSendInfo')
          }
        }
      });

    },
    /***************************************************************
     * @function fn_MoveMSPCM050M
     * @name     화면전환
     * @notice   고객등록동의 전환
    ***************************************************************/
    fn_MoveMSPCM050M() {
      // cmStore 고객정보 저장
      window.vue.getStore('cmStore').dispatch('ADD', this.lv_CustVO301D)

      let pParams = {
        srnId: 'MSPCM270M',
        custNm: this.lv_CustVO301D.custNm, // 고객명
      }

      this.$router.push({ name: 'MSPCM050M', params: pParams})
    },
    /***************************************************************
     * @function fn_BottomSheetHandler
     * @name     바텀시트_핸들링_함수
     * @param    sheetId : 바텀시트ID
     * @param    flag : close/open
    ***************************************************************/
    fn_BottomSheetHandler (sheetId, flag) {
      if (flag === 'close') {
        switch (sheetId) { // BottomSheet Close
          case 'BSCustSendInfo':
            this.$refs.BSCustSendInfo.close()
            break;
        }
      } else {
        switch (sheetId) { // BottomSheet Open
          case 'BSCustSendInfo':
            this.$refs.BSCustSendInfo.open()
            break;
        }
      }
    },
    /***************************************************************
     * @function fn_ChangeRcppl
     * @name     우편수령지_주소_체크
     * @param    rcpplSc : 우편수령지 코드(11: 자택 / 12: 직장)
    ***************************************************************/
    fn_ChangeRcppl (rcpplSc) {
      let valiMsg = ''

      if (rcpplSc === '11') { // 자택
        if (this.$bizUtil.isEmpty(this.fn_CmptdHomeAddr)) {
          valiMsg = '자택 주소가 없으므로 자택을 수령지로 선택할 수 없습니다.'
        }
      } else if (rcpplSc === '12') { // 직장
        if (this.$bizUtil.isEmpty(this.fn_CmptdJobAddr)) {
          valiMsg = '직장 주소가 없으므로 직장을 수령지로 선택할 수 없습니다.'
        }
      }

      if (!this.$bizUtil.isEmpty(valiMsg)) {
        this.lv_CustVO301D.mailRcpplScCd = ''
        this.fn_BottomAlert(valiMsg)
      }
    },
    /******************************************************************************
     * @function fn_ClearAddr
     * @notice   집, 직장 입력값 초기화
    ******************************************************************************/
    fn_ClearAddr (addrType) {
      let alertObj = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          content: '선택한 주소를 삭제할까요?'
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(alertObj)

            if (addrType === '11') { // 자택
              this.lv_CustVO301D.adtnAddr01 = ''
              this.lv_CustVO301D.bldgNo01 = ''
              this.lv_CustVO301D.homAddr = ''
              this.lv_CustVO301D.homeAddrDtal = ''
              this.lv_CustVO301D.homAddrRefnCd = ''
              this.lv_CustVO301D.homAddrScCd = ''
              this.lv_CustVO301D.homAddrStatCd = ''
              this.lv_CustVO301D.homBknoPstcd = ''
              this.lv_CustVO301D.homDtlad = ''
              this.lv_CustVO301D.homFrnoPstcd = ''
              this.lv_CustVO301D.homPstcdSno = ''
            } else if (addrType === '12') { // 직장
              this.lv_CustVO301D.adtnAddr02 = ''
              this.lv_CustVO301D.bldgNo02 = ''
              this.lv_CustVO301D.jobpAddr = ''
              this.lv_CustVO301D.jobAddrDtal = ''
              this.lv_CustVO301D.jobpAddrRefnCd = ''
              this.lv_CustVO301D.jobpAddrScCd = ''
              this.lv_CustVO301D.jobpAddrStatCd = ''
              this.lv_CustVO301D.jobpBknoPstcd = ''
              this.lv_CustVO301D.jobpDtlad = ''
              this.lv_CustVO301D.jobpFrnoPstcd = ''
              this.lv_CustVO301D.jobpPstcdSno = ''
            }
          },
          onPopupClose: () => {
            this.$bottomModal.close(alertObj)
          }
        }
      });
      
    },
    /******************************************************************************
     * @function fn_ValiMsgShow
     * @notice   유효성 체크 하단 문구 노출 메서드
    ******************************************************************************/
    fn_ValiMsgShow (valiType) {
      this.$refs.vWatcher.clearValidation()

      // this.chkErrorMsg = valiMsg
      if (valiType === 'email') {
        this.chkErrorMsg = '이메일 형식을 확인바랍니다.'
        this.chkResult = false
        this.$refs.refEmail.validate()
      } else if (valiType === 'celNo') {
        if (this.$bizUtil.isEmpty(this.lv_CelFronNo) || this.lv_CelFronNo === '0') { // 휴대번호 앞자리 미선택시
          this.chkErrorMsg = '국번을 확인바랍니다.'
        } else {
          this.chkErrorMsg = '입력하신 번호를 확인바랍니다.'
        }
        this.chkSelectResult = false
        this.chkResult = false
        this.$refs.refCelFronNo.validate()
        this.$refs.refCelBackNo.validate()
      } else if (valiType === 'homTelNo') {
        if (this.$bizUtil.isEmpty(this.lv_HomTelFronNo)) { // 자택번호 앞자리 미선택시
          this.chkErrorMsg = '지역번호를 확인바랍니다.'
        } else {
          this.chkErrorMsg = '입력하신 번호를 확인바랍니다.'
        }
        this.chkSelectResult = false
        this.chkResult = false
        this.$refs.refHomTelFronNo.validate()
        this.$refs.refHomTelNo.validate()
      } else if (valiType === 'jobTelNo') {
        if (this.$bizUtil.isEmpty(this.lv_JobTelFronNo)) { // 직장번호 앞자리 미선택시
          this.chkErrorMsg = '지역번호를 확인바랍니다.'
        } else {
          this.chkErrorMsg = '입력하신 번호를 확인바랍니다.'
        }
        this.chkSelectResult = false
        this.chkResult = false
        this.$refs.refJobTelFronNo.validate()
        this.$refs.refJobTelNo.validate()
      } else if (valiType === 'entryPath') {
        if (this.$bizUtil.isEmpty(this.lv_EntryPath)) {
          this.chkErrorMsg = '취득경로를 확인바랍니다.'
          this.chkSelectResult = false
          this.$refs.refEntryPaths.validate()
        } else {
          this.chkErrorMsg = '소개자 및 소개일를 확인바랍니다.'
          this.chkResult = false
          this.$refs.refEntryPath.validate()
          this.$refs.refEntryPathDate.validate()
        }
      } else if (valiType === 'ralDob') {
        this.chkErrorMsg = '실제생일을 확인바랍니다.'
        this.chkResult = false
        this.$refs.refRalDob.validate()
      } 
    },
    /******************************************************************************
     * @function fn_BlockChangAlert
     * @notice   휴대폰번호 수정 불가 안내 팝업 호출
     *             > 2023.05.10 ※ 금감원 지시사항 휴대폰 번호 변경 불가 ※
    ******************************************************************************/
    fn_BlockChangAlert () {
      // let alertMsg = '휴대폰번호 수정은 고객등록동의를 통해 가능합니다<br>이동하시겠습니까?'
      let alertMsg = '고객 동의를 받지않고 고객의 휴대폰번호 임의 변경은 불가합니다.<br>' +
                     '변경을 희망하신다면 고객등록동의를 받으셔야합니다.<br>' +
                     '고객등록동의 화면으로 이동하시겠습니까?'

      let pParam = {
        custNm: this.lv_CustVO301D.custNm,
        srnId: 'MSPCM270M'
      }

      let alertObj = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          content: alertMsg
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(alertObj)

            // cmStore 고객정보 저장
            window.vue.getStore('cmStore').dispatch('ADD', this.lv_CustVO301D)
            this.$router.push({ name: 'MSPCM050M', params: pParam })
          },
          onPopupClose: () => {
            this.$bottomModal.close(alertObj)
          }
        }
      });

    }
  } // ::Methods End
};
</script>
