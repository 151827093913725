/*
 * 업무구분 : 고객
 * 화 면 명 : MSPCM300P
 * 화면설명 : 고객카드 수정
 * 작 성 일 : 2023.02.16
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-page-container class="msp" title="고객카드 수정" :show-title="false" type="subpage">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="26" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow>
              <mo-button class="btn_popup_back" @click="fn_Back"></mo-button>
              <div slot="nav" class="icon-wrapper">
                <mo-icon color="white" icon-size="36px" borderless @click="fn_Back">drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main"><span>{{scrollRate > 0.5 ? '고객카드 수정' : '고객카드 수정'}}</span></div>
              <div slot="action" class="icon-wrapper">
                <mo-button shape="border" class="btn-title-style-2" @click="fn_DeletCnfmAlert" :disabled="lv_IsDeltDisable">삭제</mo-button>
              </div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area msp-list-area pt15 pb20">
              <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list">
              <div class="list-item w100 pb0">
                <div class="list-item__contents">
                  <div class="list-item__contents__title fexJsCtTy1"> 
                    <span class="name txtSkip fexInt">{{ lv_RsltBasSVO.custNm }}</span>
                    <mo-badge class="badge-sample-badge lightblue sm" text="본인" shape="status">
                      {{ fn_CmptdCustScNm }}
                    </mo-badge> 
                  </div>
                  <div class="list-item__contents__info">
                    <div class="info_box" v-if="lv_RsltBasSVO.birthDay">
                      <span class="crTy-bk1">{{ lv_RsltBasSVO.birthDay }}</span><em>|</em>
                      <span class="crTy-bk1">{{ lv_RsltBasSVO.sxdsNm }}</span><em>|</em>
                      <span class="crTy-bk1" v-if="!$bizUtil.isEmpty(lv_RsltBasSVO.celno)">
                        <mo-icon icon="msp-phone" class="crTy-bk11">msp-phone</mo-icon>
                        {{ lv_RsltBasSVO.celno }}
                      </span>
                    </div>
                  </div>
                </div>
              </div> 
              </ur-box-container>
            </ur-box-container>
          </template>

          <!-- sticky 영역 -->
          <template #sticky>
            <mo-tab-box :defaultIdx="defaultIdxs" @tab-change="fn_TabChangeHandler" ref="tabbox" class="ns-move-tab-box tween bd-T-Ty4">
              <mo-tab-content :idx="1" label="고객정보">
              </mo-tab-content>
              <mo-tab-content :idx="2" label="세대관계정보">
              </mo-tab-content>
            </mo-tab-box>
          </template>
          
          <!-- scroll 영역 -->
          <template #scroll>
            <ur-box-container direction="column" alignV="start" class="msp-cm-wrap"> 
              <MSPCM301D ref="cm301D" v-show="slctTabIdx === 1" :slctTabIdx="slctTabIdx" :pCustInfo="pCM301D" :getDataCall="call301D" @setCM301D="fn_SetCM301D" @btnHandler="fn_BottomBtnHandler"/>
              <MSPCM303D ref="cm303D" v-show="slctTabIdx === 2" :slctTabIdx="slctTabIdx" :pCustInfo="pCM303D" :getDataCall="call303D" @setCM303D="fn_SetCM303D" @callInit="fn_Init" :pAddChild="pAddChild"/>
            </ur-box-container>
            <ur-box-container v-if="ShowBottomBtn" alignV="start" componentid="" direction="column" class="ns-btn-relative">
              <div class="relative-div">
                <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Back">취소</mo-button>
                <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_GetUpdateCustInfo">저장</mo-button>
              </div>
            </ur-box-container>
          </template>
        </mo-collapsing-header-view>
      </div>
    </ur-box-container>

    <!-- BottomSheet 영역 ::START -->
    <!-- confirmAlert팝업 Bottom Sheet -->
    <mo-bottom-sheet ref="confirmAlert" class="ns-bottom-sheet msp-bottomSt-alert" >
      <div class="customer-info-txt txt-center">
        <p class="ment" v-html="confirmMsg" />
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="$refs.confirmAlert.close()">아니요</mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="$emit('onPopupConfirm', 'U')">예</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!--  안내 bottomSheet -->
    <mo-bottom-sheet ref="bottomSheetConfirmCard1" class="ns-bottom-sheet ns-style3">
      <div>
        <p class="cancel-ment left">
          1. 계약자가 동의한 경우에만 변경 가능합니다.<br/>
          계약자의 동의를 받으셨습니까?<br/>
          2. 지금 입력하신 정보는 삼성생명 온라인에 반영되면 변경내용이 고객님께 안내(SMS, e-Mail)됩니다.<br/>
          반영하시겠습니까?<br/>
          (취소시 이전 데이터 복구)<br/>
          <br/>
          ※ 고객 동의없는 정보변경은 금지행위(개인정보보호법 제59조 제3호) 이며, 민원 발생 및 과태료 책임이 발생할 수 있습니다.<br/>
          반드시 고객 본인 동의 후 처리하시기 바랍니다.<br/>
        </p>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_UpdateCnfmSheet('updateCnfm01-1', 'close')">아니요</mo-button>
            <mo-button componentid="mo_button_006" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_UpdateCnfmSheet('updateCnfm01-2', 'close')">예</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!--  안내 bottomSheet -->
    <mo-bottom-sheet ref="bottomSheetConfirmCard2" class="ns-bottom-sheet ns-style3">
      <div>
        <p class="cancel-ment left">
          고객 연락처가 실제 고객 번호와 맞는지 확인하시기 바랍니다.<br/>
          임의번호 입력 금지<br/>
          (예:010-xxxx-0000, 010-xxxx-1111)<br/>
        </p>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_UpdateCnfmSheet('updateCnfm02', 'close')">아니요</mo-button>
            <mo-button componentid="mo_button_006" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_SetCustSVO()">예</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- ######### 고객카드삭제 관련 바텀시트 ##########-->
    <!-- 세대주일 경우-->
    <mo-bottom-sheet ref="bottomSheetDeletCard1" class="ns-bottom-sheet ns-style3">
      <div>
        <p class="cancel-ment left">
          해당 주 고객에게 등록된 세대원이 있어 세대원 분리 이후 고객 삭제가 가능합니다.<br>
          세대분리는 세대조정 화면에서 조정이 가능하오니 세대 조정 후 다시 시도해주세요.
        </p>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_DeletCnfmSheet('deletCnfm01', 'close')">취소</mo-button>
            <mo-button color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_OpenMSPCM304P()">세대조정</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!-- 세대원일 경우-->
    <mo-bottom-sheet ref="bottomSheetDeletCard2" class="ns-bottom-sheet ns-style3">
      <div>
        <p class="cancel-ment left">
          해당 고객의 정보와 활동이력이 모두 삭제됩니다.<br>
          세대분리를 원하시는 경우 세대조정을 선택하시고 모든 정보의 삭제를 원하시면 삭제를 선택하세요.
        </p>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_OpenMSPCM304P()">세대조정</mo-button>
            <mo-button color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_DeletCustInfo">삭제</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!-- BottomSheet 영역 ::END -->

  </ur-page-container>
</template>

<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import CmConstants from '@/config/constants/cmConstants'
import moment from 'moment'

import MSPCM301D from '@/ui/cm/MSPCM301D' // 고객정보
import MSPCM303D from '@/ui/cm/MSPCM303D' // 세대 관계 정보
import MSPCM304P from '@/ui/cm/MSPCM304P' // 세대 조정 팝업
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  // 현재 화면명
  name: 'MSPCM300P', 
  // 현재 화면 ID
  screenId: 'MSPCM300P',
  isBackKeyHandle : true , // 팝업일때 디바이스 백키 직접 핸들 처리 여부 옵션
  // 컴포넌트 정의
  components: {
    MSPCM301D, // 고객정보
    MSPCM303D  // 세대 관계 정보
  },
  /***********************************************************************************
   * 화면 UI Property 정의
  ***********************************************************************************/
  props: {
    callerId: {
      type: String,
      default: 'MSPCM270M'
    },
    viewId: {
      type: String,
      default: 'MSPCM301D'
    },
    pChnlCustId: {
      type: String,
      default: ''
    },
    pCnsltNo: {
      type: String,
      default: ''
    },
    pDeltDisable: {
      type: Boolean,
      default: true
    },
    pAddChild: {
      type: Boolean,
      default: false
    }
  },

  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /***********************************************************************************
   * 화면 전역 변수 선언 영역
  ***********************************************************************************/
  data () {
    return {
      title: '고객카드 수정',

      // 고객상세 정보
      lv_RsltBasSVO: {},
      
      pCM301D: { // MSPCM301D
        lv_RsltBasSVO: {}
      },
      pCM303D: { // MSPCM303D
        lv_RsltBasSVO: {}
      },

      call301D: false, // MSPCM301D 수정값 호출용도
      call303D: false, // MSPCM303D 수정값 호출용도

      lv_Params: {},
      lv_IsDeltDisable: false, // 삭제버튼 비활성화 여부
      lv_IsSuccessUpate: false, // 고객카드 수정 여부

      slctTabIdx : 1, // 현재 선택된 탭 인덱스 값 1 : 완료건 2:중단건
      defaultIdxs : 1,
      tabList: [ {index: '1', menuId: 'MSPCM301D', label: '고객정보'},
                 {index: '2', menuId: 'MSPCM303D', label: '세대관계정보'} ],

      // 스크롤 대상 컴포넌트 ID
      lv_HeaderviewScrollCompID: '',
      
      confirmMsg: '', // 알럿 바텀시트 내용

      ShowBottomBtn: true // 키패드 노출 여부
    }
  },
  /***********************************************************************************
   * watch 정의 영역                                                                 *
  ***********************************************************************************/
  watch: {
    lv_RsltBasSVO() {
      if (this.pAddChild) {
        this.lv_RsltBasSVO.addChild = true
      }

      // MSPCM301D
      this.pCM301D.lv_RsltBasSVO = this.lv_RsltBasSVO
      // MSPCM303D
      this.pCM303D.lv_RsltBasSVO = this.lv_RsltBasSVO
    }
  },
  computed: {
    // 태그정의 고객구분(고객유형): 본인모집, 타인모집, 관심, 임시, 가망
    fn_CmptdCustScNm() {
      const lv_Vm = this

      let custScObj = CmConstants.CUST_CNTC_INFO_CUST_SC_CD.filter(item => {
            return item.value === lv_Vm.lv_RsltBasSVO.chnlCustScCd
        })[0];

      if (!_.isEmpty(custScObj)) {
        lv_Vm.lv_RsltBasSVO.chnlCustScNm = custScObj.text
      }

      return custScObj ? custScObj.text : ''
    }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  created() {
    console.log('■■■■■■■ MSPCM300P ■■■■■■■')

    let tabIndex = this.viewId === 'MSPCM301D' ? 1 : 2
    // TabBox 체크표시
    this.defaultIdxs = tabIndex
    this.fn_TabChangeHandler(tabIndex)

    // 디바이스 Back Key event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_BtnPopupHandler)
  },
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM300P')
    // 스크롤 애니메이션 instance 선언
    this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()

    this.fn_Init();
  },
  destroyed() {
    // 디바이스 Back Key event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_BtnPopupHandler)
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /*********************************************************
     * @function fn_Init
     * @notice   화면진입시, 최초 실행 함수
     *           초기 셋팅 및 설정
    *********************************************************/
    fn_Init () {
      const lv_Vm = this

      this.lv_IsDeltDisable = this.pDeltDisable
      if (!this.$bizUtil.isEmpty(this.pChnlCustId) && !this.$bizUtil.isEmpty(this.pCnsltNo)) {
        this.lv_ChnlCustId = this.pChnlCustId
        this.lv_CnsltNo = this.pCnsltNo

        this.fn_SelCustCardInfo()
      }
    },
    /******************************************************************************
     * @function fn_SelCustCardInfo
     * @name     고객카드조회
     * @notice   고객상세인적사항 정보 조회한다.
    *********************************************************/
    fn_SelCustCardInfo () {
      const lv_Vm = this

      let pParam = {
        chnlCustId: this.lv_ChnlCustId, // 채널고객ID
        cnsltNo: this.lv_CnsltNo, // 담당 컨설턴트ID
        conctScCd: "1",
        whlInqrYn: "Y"
      }

      const trnstId = 'txTSSCM11S1'
      const auth = 'S'
      this.post(lv_Vm, pParam, trnstId, auth)
        .then(res => {
          if (lv_Vm.$commonUtil.isSuccess(res)) {
            if (!_.isEmpty(res.body)) {
              lv_Vm.lv_RsltBasSVO = res.body
              lv_Vm.lv_OrgRsltBasSVO = this.$bizUtil.cloneDeep(lv_Vm.lv_RsltBasSVO)

              let fullBirthDay = ''
              // 생년월일
              fullBirthDay = lv_Vm.$cmUtil.getFullBirthYear(lv_Vm.lv_RsltBasSVO.knb).substr(0,8)
              lv_Vm.lv_RsltBasSVO.birthDay = lv_Vm.$commonUtil.dateDashFormat(fullBirthDay)

              lv_Vm.lv_RsltBasSVO.sxdsCd = lv_Vm.lv_RsltBasSVO.knb.substring(6, 7) // 성별코드
              lv_Vm.lv_RsltBasSVO.sxdsNm = lv_Vm.$cmUtil.getSxdsNmByRrn(lv_Vm.lv_RsltBasSVO.knb)

              lv_Vm.lv_RsltBasSVO.iCHoshInfoListInqrVO.forEach(item => {
                // 생년월일
                fullBirthDay = lv_Vm.$cmUtil.getFullBirthYear(item.knb).substr(0,8)
                item.birthDay = lv_Vm.$commonUtil.dateDashFormat(fullBirthDay)

                item.sxdsCd = item.knb.substring(6, 7) // 성별코드
                item.sxdsNm = lv_Vm.$cmUtil.getSxdsNmByRrn(item.knb)
              });

            } else { // 고객카드 정보가 없거나 변경, 삭제된 경우
              // 대면용을 제외한 업무용 UI 퍼플 색상 적용 요청 (requested by 황고은 선임), modified by anarchi, 2018.12.20
              // lv_Vm.getStore('confirm').dispatch('ADD', '삭제된 고객이거나 해당 고객카드 정보가 없습니다.')
            }
          } else { // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
            // lv_Vm.getStore('confirm').dispatch('ADD', res.msgComm.msgDesc)
          }
        });

    },
    /*********************************************************
     * @function fn_BtnPopupHandler
     * @notice   고객카드 수정(U)/삭제(D)/취소(C)
    *********************************************************/
    fn_BtnPopupHandler (falg) {
      if (falg === 'U') { // 수정후, 고객카드 탭 자동 세팅
        let rtnObj = {
          custInfo: {
            updtFlag: this.slctTabIdx, // 선택한 탭 Index
            chnlCustId: this.lv_RsltBasSVO.chnlCustId, // 채널고객ID
            cnsltNo: this.lv_RsltBasSVO.cnsltNo, // 담당컨설턴트사번
          }
        }

        this.$emit('onPopupConfirm', falg, rtnObj)
      } else if (falg === 'D') {
        this.$emit('onPopupConfirm', falg)
      } else {
        if (falg !== 'C') {
          // 디비젼 데이터 셋팅 호출
          this.call301D = !this.call301D // fn_SetCM301D 실행
          this.call303D = !this.call303D // fn_SetCM303D 실행
        }
        this.$nextTick(_ => {
          this.fn_CheckChangeInfo()
        })
      }
    },
    /*********************************************************
     * @function fn_Back
     * @notice   뒤로가기 버튼 클릭
     *            => 팝업 닫은후, 고객카드 재조회 실행
    *********************************************************/
    fn_Back () {
      let falg = 'U'
      let rtnObj = {
        custInfo: {
          updtFlag: this.slctTabIdx, // 선택한 탭 Index
          chnlCustId: this.lv_RsltBasSVO.chnlCustId, // 채널고객ID
          cnsltNo: this.lv_RsltBasSVO.cnsltNo, // 담당컨설턴트사번
        }
      }

      this.$emit('onPopupConfirm', falg, rtnObj)
    },
    /*********************************************************
     * @function fn_SuitaDeletCheck
     * @notice   고객카드 삭제 조건 적합 체크
    *********************************************************/
    fn_SuitaDeletCheck () {
      if (_.isEmpty(this.lv_RsltBasSVO)) return

      // 세대주 여부('01':본인) 확인용
      let _mnCustRltnCd = this.lv_RsltBasSVO.mnCustRltnCd
      // 세대원 존재여부 확인용
      let _hoshInfoList = this.lv_RsltBasSVO.iCHoshInfoListInqrVO
      
      if (_.isEmpty(_hoshInfoList)) {
        // 고객카드 삭제 진행...
        this.fn_DeletCustInfo()
      } else {
        if (_mnCustRltnCd === '01') {
          this.fn_DeletCnfmSheet('deletCnfm01')
        } else {
          this.fn_DeletCnfmSheet('deletCnfm02')
        }
      }
    },
    /*********************************************************
     * @function fn_DeletCustInfo
     * @name     고객카드삭제
    *********************************************************/
    fn_DeletCustInfo () {
      const lv_Vm = this

      // 컨펌 바텀시트 Close
      this.fn_DeletCnfmSheet('deletCnfm01', 'close')
      this.fn_DeletCnfmSheet('deletCnfm02', 'close')

      let pParam = lv_Vm.lv_RsltBasSVO
      
      pParam.chnlCustScCd    = !this.$bizUtil.isEmpty(this.lv_RsltBasSVO.chnlCustScCd) ? this.lv_RsltBasSVO.chnlCustScCd : '02'
      pParam.chnlCstgrRltnCd = !this.$bizUtil.isEmpty(this.lv_RsltBasSVO.mnCustRltnCd) ? this.lv_RsltBasSVO.mnCustRltnCd : '02'
      pParam.chnlCstgrId = this.lv_RsltBasSVO.chnlCstgrId
      pParam.prcsfCd = CmConstants.AUTH_DELETE

      const trnstId = 'txTSSCM11D0'
      const auth = 'U'
      this.post(lv_Vm, pParam, trnstId, auth)
        .then(res => {
          if (!_.isEmpty(res.body)) {
            let toastMsg = '고객의 카드 정보가 삭제되었습니다.'
            this.$addSnackbar(toastMsg)
            lv_Vm.fn_BtnPopupHandler('D')
          } else {
            let alertMsg = res.msgComm.msgDesc
            lv_Vm.fn_OpenBottomAlert(alertMsg)
          }
        })
        .catch(error => {
          window.vue.error(error)
        });

    },
    /*********************************************************
     * @function fn_Validate
     * @notice   이메일, 소개자 유효성 체크
     *            (2023.04.27 입력란 문구 노출 요건추가)
    *********************************************************/
    async fn_Validate () {
      let alertMsg = ''
      this.$refs['cm301D'].fn_ValiMsgShow('')

      // 실제생일 체크
      if (!this.$bizUtil.isEmpty(this.lv_Params.ralDob)) {
        const serverTime = await this.$commonUtil.getServerTime();
        const today = moment(serverTime).format('YYYYMMDD');
        if (this.lv_Params.ralDob > today) {
          alertMsg = '실제생일이 현재보다 미래일 수 없습니다.'
          this.fn_OpenBottomAlert(alertMsg)

          this.$refs['cm301D'].fn_ValiMsgShow('ralDob')
          return
        }
      }
      
      // 이메일 주소 형식 체크
      if (!this.$bizUtil.isEmpty(this.lv_Params.emailAddr)) {
        if (!this.$cmUtil.fn_CheckSuitEmail(this.lv_Params.emailAddr)) { // 이메일 형식 체크
          alertMsg = '잘못된 이메일 형식입니다.'
          this.fn_OpenBottomAlert(alertMsg)

          this.$refs['cm301D'].fn_ValiMsgShow('email')
          return
        }
      }
      
      // 휴대번호 체크 (테블릿과 동일하게 뒷번호 자리수 체크)
      if (this.lv_Params.celFronNo === '0' || this.$bizUtil.isEmpty(this.lv_Params.celFronNo)) {
        if (!this.$bizUtil.isEmpty(this.lv_Params.celBackNo)) {
          alertMsg = '휴대폰번호를 확인하세요.<br><br>※ 임의로 수정하는 경우 고객민원 및 감사대상이므로 주의하시기 바랍니다.'
          this.fn_OpenBottomAlert(alertMsg)

          this.$refs['cm301D'].fn_ValiMsgShow('celNo')
          return
        }
      } else {
        if (this.lv_Params.celFronNo === '010') {
          if (this.lv_Params.celBackNo.length !== 8) {
            alertMsg = '휴대폰번호를 확인하세요.<br><br>※ 임의로 수정하는 경우 고객민원 및 감사대상이므로 주의하시기 바랍니다.'
            this.fn_OpenBottomAlert(alertMsg)

            this.$refs['cm301D'].fn_ValiMsgShow('celNo')
            return
          }
        } else {
          if (this.$bizUtil.isEmpty(this.lv_Params.celBackNo) || this.lv_Params.celBackNo.length < 7) {
            alertMsg = '휴대폰번호를 확인하세요.<br><br>※ 임의로 수정하는 경우 고객민원 및 감사대상이므로 주의하시기 바랍니다.'
            this.fn_OpenBottomAlert(alertMsg)

            this.$refs['cm301D'].fn_ValiMsgShow('celNo')
            return
          }
        }
      }

      // 자택 주소 유효성 체크
      if (this.lv_Params.homFrnoPstcd === '00000') {
        alertMsg = '자택 주소정보를 확인해주세요.'
        this.fn_OpenBottomAlert(alertMsg)

        return
      }

      // 자택 전화번호 체크
      let homTelNo = this.lv_Params.homTelFronNo + this.lv_Params.homTelBackNo

      if (this.lv_Params.homTelFronNo === '0' || this.$bizUtil.isEmpty(this.lv_Params.homTelFronNo)) {
        if (!this.$bizUtil.isEmpty(this.lv_Params.homTelBackNo)) {
          alertMsg = '연락처(자택)를 확인하세요.<br><br>※ 임의로 수정하는 경우 고객민원 및 감사대상이므로 주의하시기 바랍니다.'
          this.fn_OpenBottomAlert(alertMsg)

          this.$refs['cm301D'].fn_ValiMsgShow('homTelNo')
          return
        }
      } else {
        if (this.lv_Params.homTelFronNo === '010') {
          if (this.lv_Params.homTelBackNo.length !== 8) {
            alertMsg = '연락처(자택)를 확인하세요.<br><br>※ 임의로 수정하는 경우 고객민원 및 감사대상이므로 주의하시기 바랍니다.'
            this.fn_OpenBottomAlert(alertMsg)

            this.$refs['cm301D'].fn_ValiMsgShow('homTelNo')
            return
          }
        } else {
          if (this.$bizUtil.isEmpty(this.lv_Params.homTelBackNo) || this.lv_Params.homTelBackNo.length < 7) {
            alertMsg = '연락처(자택)를 확인하세요.<br><br>※ 임의로 수정하는 경우 고객민원 및 감사대상이므로 주의하시기 바랍니다.'
            this.fn_OpenBottomAlert(alertMsg)

            this.$refs['cm301D'].fn_ValiMsgShow('homTelNo')
            return
          }
        }
      }

      // 직장 주소 유효성 체크
      if (this.lv_Params.jobpFrnoPstcd === '00000') {
        alertMsg = '직장 주소정보를 확인해주세요.'
        this.fn_OpenBottomAlert(alertMsg)

        return
      }

      // 직장 전화번호 체크
      let jobTelNo = this.lv_Params.jobTelFronNo + this.lv_Params.jobTelBackNo

      if (this.lv_Params.jobTelFronNo === '0' || this.$bizUtil.isEmpty(this.lv_Params.jobTelFronNo)) {
        if (!this.$bizUtil.isEmpty(this.lv_Params.jobTelBackNo)) {
          alertMsg = '연락처(직장)를 확인하세요.<br><br>※ 임의로 수정하는 경우 고객민원 및 감사대상이므로 주의하시기 바랍니다.'
          this.fn_OpenBottomAlert(alertMsg)

          this.$refs['cm301D'].fn_ValiMsgShow('jobTelNo')
          return
        }
      } else {
        if (this.lv_Params.jobTelFronNo === '010') {
          if (this.lv_Params.jobTelBackNo.length !== 8) {
            alertMsg = '연락처(직장)를 확인하세요.<br><br>※ 임의로 수정하는 경우 고객민원 및 감사대상이므로 주의하시기 바랍니다.'
            this.fn_OpenBottomAlert(alertMsg)

            this.$refs['cm301D'].fn_ValiMsgShow('jobTelNo')
            return
          }
        } else {
          if (this.$bizUtil.isEmpty(this.lv_Params.jobTelBackNo) || this.lv_Params.jobTelBackNo.length < 7) {
            alertMsg = '연락처(직장)를 확인하세요.<br><br>※ 임의로 수정하는 경우 고객민원 및 감사대상이므로 주의하시기 바랍니다.'
            this.fn_OpenBottomAlert(alertMsg)

            this.$refs['cm301D'].fn_ValiMsgShow('jobTelNo')
            return
          }
        }
      }

      // 취득경로 유효성 체크
      if (this.$bizUtil.isEmpty(this.lv_Params.entryPath)) {
        alertMsg = '취득경로를 반드시 선택하시기 바랍니다.'
        this.fn_OpenBottomAlert(alertMsg)

        this.$refs['cm301D'].fn_ValiMsgShow('entryPath')
        return
      }

      // 취득경로 소개 - 소개자/소개일 체크
      if (this.lv_Params.entryPath === 'intdr') {
        if (this.$bizUtil.isEmpty(this.lv_Params.custNm01) || this.$bizUtil.isEmpty(this.lv_Params.introYmd)){
          alertMsg = "취득경로(소개)의 '소개자' 및 '소개일'을 확인하시기 바랍니다."
          this.fn_OpenBottomAlert(alertMsg)

          this.$refs['cm301D'].fn_ValiMsgShow('entryPath')
          return
        }
      }

      // 세대원 주고객관계 적합성 체크
      let valiHosh = this.$refs['cm303D'].fn_ValidateHosh()

      if (!valiHosh) {
        return
      }

      if (this.lv_Params.chnlCustScCd === '01') { // 가망고객일 경우
        this.fn_UpdateCnfmSheet('updateCnfm02')
      } else if (this.lv_Params.chnlCustScCd === '02') { // 임시고객일 경우
        let contentMsg = this.$t('cm')['ECMC069'].replace('{0}', this.lv_Params.custNm) // {0}님은 NICE 실명확인 대상이 아닙니다.
        this.fn_OpenBottomNiceAlert(contentMsg)
      } else {
        // 계약 고객일 경우 
        this.fn_UpdateCnfmSheet('updateCnfm01')
      }
    },
    /*********************************************************
     * @function fn_SetCustSVO
     * @notice   디비젼 데이터 호출 및 파라미터 셋팅
     *              >> 업데이트 함수 호출
    *********************************************************/
    fn_SetCustSVO () {
      if (!_.isEmpty(this.lv_Params)) {
        this.lv_Params.prcsfCd = 'U'
        this.lv_Params.aperso = this.lv_Params.aperso.trim()

        this.lv_Params.iCHoshInfoListInqrVO = this.lv_Params.iCHoshInfoListInqrVO.filter(item => {
          return item.prcsfCd === 'I' || item.prcsfCd === 'U'
        })
        
        this.fn_UpdateCnfmSheet('updateCnfm02', 'close')
        this.fn_UpdCustCardInfo()
      }
    },
    /*********************************************************
     * @function fn_SetCM301D()_/_fn_SetCM303D()
     * @notice   각 디비젼에서 입력된 데이터 가져와 셋팅한다.
    *********************************************************/
    fn_SetCM301D (pData) {
      this.lv_Params = Object.assign({}, pData)
      let hoshList = pData.iCHoshInfoListInqrVO
      this.lv_Params.iCHoshInfoListInqrVO = !_.isEmpty(hoshList) ? hoshList : []

      // 세대원 취득경로 주고객과 동일하게 처리 (필요시 주석 해제)
      // this.lv_Params.iCHoshInfoListInqrVO = []
      // if (!_.isEmpty(hoshList)) {
      //   hoshList.forEach(item => {
      //     item.acutnCustYn = pData.acutnCustYn  // 지인
      //     item.aptCustYn = pData.aptCustYn      // 개척
      //     item.rspnDstrtCustYn = pData.rspnDstrtCustYn  // 기타
      //     item.custNm01 = pData.custNm01  // 소개자명
      //     item.introYmd = pData.introYmd  // 소개일자
      //   })
      //   this.lv_Params.iCHoshInfoListInqrVO = hoshList
      // }
    },
    fn_SetCM303D (pData) {
      this.lv_Params.iCHoshInfoListInqrVO = !_.isEmpty(pData) ? pData : []
      this.lv_RsltBasSVO.iCHoshInfoListInqrVO = this.lv_Params.iCHoshInfoListInqrVO
      // this.fn_SaveConfmSheet()
    },
    /*********************************************************
     * @function fn_UpdCustCardInfo
     * @notice   고객카드 수정 서비스 호출한다.
     *            외국인 진위여부 패스하게되면 BPID 존재하지않아 응답데이터 존재X
     *             > 수정처리는 정상적으로 진행됨..(테블릿 동일)
    *********************************************************/
    fn_UpdCustCardInfo () {
      const lv_Vm = this

      let pParams = this.lv_Params

      const trnstId = 'txTSSCM11U0'
      const auth = 'U'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          if (!_.isEmpty(res.body)) {
            lv_Vm.lv_IsSuccessUpate = true
            let alertMsg = '저장되었습니다.'
            lv_Vm.fn_OpenBottomAlert(alertMsg)
          } else {
            // 외국인 정보 변경 여부 체크
            if (!this.lv_Params.isChangeForigInfo) {
              lv_Vm.getStore('confirm').dispatch('ADD', res.msgComm.msgDesc)
            } else {
              // 팝업 닫고 고객카드 재조회 (ERP에서 고객별 BP동기화 프로세스로 BPID 체크하여 별도 예외처리)
              lv_Vm.fn_BtnPopupHandler('U')
            }
          }
        })
        .catch(error => {
          window.vue.error(error)
        });

    },
    // ------------------------------------------------------------------------------- //
    // -------------------------------- ALERT ---------------------------------------- //
    /*********************************************************
     * @function fn_DeletCnfmAlert
     * @notice   고객카드 삭제 컨펌 알럿
    *********************************************************/
    fn_DeletCnfmAlert () {
      const lv_Vm = this
      let alertMsg = '해당고객의 정보와 활동이력이 모두 삭제됩니다.<br>세대분리를 원하시면 취소 후 세대조정 버튼을 누르시고, 모든 정보의 삭제를 원하시면 아래의 삭제 버튼을 선택하세요.'
      let lv_Alert = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            title_pos_btn: '삭제',
            content: alertMsg
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(lv_Alert)
              lv_Vm.fn_SuitaDeletCheck()
            },
            onPopupClose: () => {
              this.$bottomModal.close(lv_Alert)
            },
          }
        });

    },
    /*********************************************************
     * @function fn_SaveConfmSheet
     * @notice   고객 정보 저장 컨펌 호출
    *********************************************************/
    fn_SaveConfmSheet () {
      const lv_Vm = this

      let lv_Alert = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          content: '저장하시겠습니까?'
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(lv_Alert)

            setTimeout(() => {
              lv_Vm.fn_Validate()
            },500);
          },
          onPopupClose: () => {
            this.$bottomModal.close(lv_Alert)
          },
        }
      },
      {
        properties: {
          preventTouchClose: false
        }
      });

    },
    /*********************************************************
     * @function fn_OpenBottomAlert
     * @notice   바텀시트 알림 알럿
     *           수정완료 알럿 확인시, 팝업 Close
    *********************************************************/
    fn_OpenBottomAlert (alertMsg) {
      const lv_Vm = this

      let alertObj = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          content: alertMsg
        },
        listeners: {
          onPopupConfirm: () => {
            if (lv_Vm.lv_IsSuccessUpate) {
              lv_Vm.fn_BtnPopupHandler('U')
            }
            this.$bottomModal.close(alertObj)
          }
        }
      });
      
    },
    /*********************************************************
     * @function fn_OpenBottomNiceAlert
     * @notice   바텀시트 알림 알럿
     *           NICE 실명확인 안내 알럿
    *********************************************************/
    fn_OpenBottomNiceAlert(alertMsg) {
      const lv_Vm = this

      let alertObj = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          content: alertMsg
        },
        listeners: {
          onPopupConfirm: () => {
            lv_Vm.fn_UpdateCnfmSheet('updateCnfm02')
            this.$bottomModal.close(alertObj)
          },
          onPopupClose: () => {
            this.$bottomModal.close(alertObj)
          }
        }
      });

    },
    // -------------------------------- ALERT ---------------------------------------- //

    // ----------------------------- BottomSheet ------------------------------------- //
    /*********************************************************
     * @function fn_DeletCnfmSheet
     * @notice   고객카드 삭제 컨펌 시트
    *********************************************************/
    fn_DeletCnfmSheet (sheetId, flag) {
      if (flag === 'close') {
        switch (sheetId) {
          case 'deletCnfm01':
            this.$refs.bottomSheetDeletCard1.close()
            break;
          case 'deletCnfm02':
            this.$refs.bottomSheetDeletCard2.close()
            break;
        }
      } else {
        switch (sheetId) {
          case 'deletCnfm01':
            this.$refs.bottomSheetDeletCard1.open()
            break;
          case 'deletCnfm02':
            this.$refs.bottomSheetDeletCard2.open()
            break;
        }
      }
    },
    /*********************************************************
     * @function fn_UpdateCnfmSheet
     * @notice   고객카드 수정 컨펌 시트
    *********************************************************/
    fn_UpdateCnfmSheet (sheetId, flag) {
      if (flag === 'close') {
        switch (sheetId) {
          case 'updateCnfm01-1':
            this.$refs.bottomSheetConfirmCard1.close()
            break;
          case 'updateCnfm01-2':
            this.$refs.bottomSheetConfirmCard1.close()
            this.$refs.bottomSheetConfirmCard2.open()
            break;
          case 'updateCnfm02':
            this.$refs.bottomSheetConfirmCard2.close()
            break;
        }
      } else {
        switch (sheetId) {
          case 'updateCnfm01':
            this.$refs.bottomSheetConfirmCard1.open()
            break;
          case 'updateCnfm02':
            this.$refs.bottomSheetConfirmCard2.open()
            break;
        }
      }
    },
    /*********************************************************
     * @function fn_GetUpdateCustInfo
     * @notice   고객정보 / 세대관계정보 탭 핸들러
    *********************************************************/
    fn_GetUpdateCustInfo () {
      // 디비젼 데이터 셋팅 호출
      this.call301D = !this.call301D // fn_SetCM301D 실행
      this.call303D = !this.call303D // fn_SetCM303D 실행

      this.fn_SaveConfmSheet()
    },
    // ----------------------------- BottomSheet ------------------------------------- //

    /*********************************************************
     * @function fn_TabChangeHandler
     * @notice   고객정보 / 세대관계정보 탭 핸들러
    *********************************************************/
    fn_TabChangeHandler (idx) {
      this.slctTabIdx = Number(idx)
    },
    /*********************************************************
     * @function fn_OpenMSPCM304P
     * @name     팝업호출
     * @notice   세대조정 팝업 호출
    *********************************************************/
    fn_OpenMSPCM304P () {
      const lv_Vm = this
      this.fn_DeletCnfmSheet('deletCnfm01', 'close')

      let popObj = this.$bottomModal.open(MSPCM304P, {
        properties: {
          pCustInfo: lv_Vm.lv_RsltBasSVO
        },
        listeners: {
          onPopupConfirm: () => {
            // 고객정보 - 기본정보 재조회
            this.fn_Init()
            this.$bottomModal.close(popObj)
          },
          onPopupClose: () => {
            this.$bottomModal.close(popObj)
          }
        }
      });

    },
    /*********************************************************
     * @function fn_BottomBtnHandler
     * @notice   키패드 노출시, 하단 버튼 숨김
    *********************************************************/
    fn_BottomBtnHandler (pData) {
      this.ShowBottomBtn = pData
      console.log(pData)
    },
    /*********************************************************
     * @function fn_CheckChangeInfo
     * @name     수정여부체크
     * @notice   뒤로가기, 취소버튼 클릭시 수정 여부 체크
                 수정 사항 존재시, 컨펌 알럿 노출 2023.05.04 개선
    *********************************************************/
    fn_CheckChangeInfo() {
      let chkCnt = 0
      
      this.lv_OrgRsltBasSVO.jobpNm.trim() !== this.lv_Params.jobpNm ? chkCnt++ : ''   // 직업명
      this.lv_OrgRsltBasSVO.celno !== this.lv_Params.celno ? chkCnt++ : ''    // 전화번호
      this.lv_OrgRsltBasSVO.emailAddr.trim() !== this.lv_Params.emailAddr ? chkCnt++ : ''   // 이메일

      this.lv_OrgRsltBasSVO.jobCd !== this.lv_Params.jobCd ? chkCnt++ : ''      // 직업코드
      this.lv_OrgRsltBasSVO.jobCdNm !== this.lv_Params.jobCdNm ? chkCnt++ : ''  // 직업코드명

      this.lv_OrgRsltBasSVO.homAddr !== this.lv_Params.homAddr ? chkCnt++ : ''  // 자택주소
      this.lv_OrgRsltBasSVO.homTelno.trim() !== this.lv_Params.homTelno ? chkCnt++ : '' // 자택전화번호

      this.lv_OrgRsltBasSVO.jobpAddr !== this.lv_Params.jobpAddr ? chkCnt++ : '' // 직장주소
      this.lv_OrgRsltBasSVO.jobpTelno.trim() !== this.lv_Params.jobpTelno ? chkCnt++ : '' // 직장전화번호

      this.lv_OrgRsltBasSVO.mailRcpplScCd !== this.lv_Params.mailRcpplScCd ? chkCnt++ : '' // 우편물 수령지

      // 취득경로
      this.lv_OrgRsltBasSVO.acutnCustYn !== this.lv_Params.acutnCustYn ? chkCnt++ : ''
      this.lv_OrgRsltBasSVO.aptCustYn !== this.lv_Params.aptCustYn ? chkCnt++ : ''
      this.lv_OrgRsltBasSVO.rspnDstrtCustYn !== this.lv_Params.rspnDstrtCustYn ? chkCnt++ : ''

      // 소개자/소개일
      this.lv_OrgRsltBasSVO.custNm01.trim() !== this.lv_Params.custNm01 ? chkCnt++ : ''
      this.lv_OrgRsltBasSVO.introYmd !== this.lv_Params.introYmd ? chkCnt++ : ''
      this.lv_OrgRsltBasSVO.intdrChnlCustId.trim() !== this.lv_Params.intdrChnlCustId ? chkCnt++ : '' // 소개자 채널고객ID

      this.lv_OrgRsltBasSVO.ralDob !== this.lv_Params.ralDob ? chkCnt++ : '' // 실제생년월일

      this.lv_OrgRsltBasSVO.dmclScCd !== this.lv_Params.dmclScCd ? chkCnt++ : ''   // 국적유형코드
      this.lv_OrgRsltBasSVO.custEngNm !== this.lv_Params.custEngNm ? chkCnt++ : '' // 영문명
      this.lv_OrgRsltBasSVO.psprtNo !== this.lv_Params.psprtNo ? chkCnt++ : ''     // 여권번호

      let hoshInfoList = this.lv_OrgRsltBasSVO.iCHoshInfoListInqrVO.filter(item => {
          return item.custNm !== '태아'
        })

      if (!_.isEmpty(this.lv_Params.iCHoshInfoListInqrVO)) {
        hoshInfoList.length !== this.lv_Params.iCHoshInfoListInqrVO.length ? chkCnt++ : '' // 세대원 인원 체크
      }

      // 수정 취소시 안내 팝업 호출
      if (chkCnt > 0) {
        this.confirmMsg = '고객카드 수정을 취소하고 이전화면으로 이동하시겠습니까?'
        this.$refs.confirmAlert.open()
      } else {
        // this.$emit('onPopupClose')
        // 2023.05.24 취소/뒤로가기 버튼 눌렀을시, 동일하게 고객카드에서 재조회
        this.fn_Back()
      }
    }
  } // ::Methods End
};
</script>
