/*
 * 업무구분 : 고객
 * 화 면 명 : MSPCM309P
 * 화면설명 : 외국인-본인인증
 * 작 성 일 : 2023.05.04
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-page-container class="msp" title="휴대폰 인증" :show-title="true" type="subpage" action-type="none">

    <!-- 컨텐츠 영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area pt30 mb100">
        <ur-box-container alignV="start" componentid="" direction="column" class="add-box pb50">
          <span class="contain-title">고객명 </span>  
          <mo-text-field class="form-input-name input_disabled--gray" underline readonly v-model="lv_CustInfo.custNm"/>
          <div class="ns-check fex-clumn mt10">
            <mo-checkbox v-model="lv_CustInfo.forigChk" disabled>외국인</mo-checkbox>
          </div> 
        </ur-box-container>  
        <ur-box-container alignV="start" componentid="" direction="column" class="add-box pb50">
          <span class="contain-title">외국인등록번호</span>  
          <div class="verify-birth">
            <mo-text-field  type="number" underline readonly class="birth-input input_disabled--gray" v-model="lv_CustInfo.knbFrno"/>
            <span class="hyphen ml10 mr10"></span>
            <mo-text-field underline readonly class="birth-input no-ic input_disabled--gray" :value="lv_CustInfo.knbBkFstNo + '******'"/>
          </div>
        </ur-box-container>  
        <ur-box-container alignV="start" componentid="" direction="column" class="add-box pb0">
          <span class="contain-title">휴대폰번호 </span>  
          <div class="verify-phone msp-verify-phone mb10">
            <msp-bottom-select ref="bottomSelect" :items="telecomTypes" v-model="fn_TelecomType" underline class="ns-dropdown-sheet w150px mr10" placeholder="선택" bottom-title="통신사" closeBtn scrolling  />
            <msp-bottom-select ref="bottomSelect1" :items="phoneItems" underline class="ns-dropdown-sheet w150px mr10" v-model="lv_CelnoFrno" bottom-title="휴대폰 번호" closeBtn scrolling/>
            <mo-text-field type="number" underline readonly v-model="lv_CustInfo.celBackNo" class="mb0 txt-left" />
          </div>
           <div class="ns-check arrow fex-clumn mb10" > 
            <mo-checkbox v-model="agreeCheckbox" @input="fn_AgreeCnfrmPop('Open')">본인확인 서비스 이용동의</mo-checkbox>
          </div>
        </ur-box-container>  
        <ur-box-container alignV="start" componentid="ur_box_container_006"  direction="column" class="ns-btn-relative-area mt0 mb0 pl0 pr0 pb50">
          <div class="ns-btn-relative-s w100">
            <div class="relative-div mb0">
              <mo-button color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_SelfAuth">
                {{!authNumberDisabled ? '인증번호 재전송' : '인증번호 전송'}}
              </mo-button>
            </div>
            <!-- 인증번호 Skip -->
            <div class="relative-div mb0" v-if="isDevelopment">
              <mo-button color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_AuthSkip">인증번호스킵</mo-button>
            </div>
            <!-- 인증번호 Skip -->
          </div>
        </ur-box-container> 

        <ur-box-container alignV="start" componentid="" direction="column" class="add-box ns-verify pb0">
          <span class="contain-title">인증번호</span>

          <ur-box-container alignV="start" componentid="ur_box_container_007" direction="column" class="verify-input">
            <!-- 인증번호 입력-->
            <mo-text-field  type="number" class="form-input-name" ref="refAuthNumber" v-model="lv_AuthNumber" underline mask="######"
                          placeholder="인증번호를 입력해주세요." :disabled="authNumberDisabled"/>
            <span v-if="isAuthCompleted">인증되었습니다.</span>

            <span v-if="isCountStart">인증번호 만료까지 {{ minutes | twoDigits }}:{{ seconds | twoDigits }}</span>
          </ur-box-container>

        </ur-box-container>  
        <ur-box-container alignV="start" componentid="ur_box_container_008" direction="column" class="verify-btn">
          <span class="txt" style="color: #6b7684">
            인증번호 미도착시 아래와 같이 조치 부탁드립니다.<br>
            휴대폰 스팸번호 분류확인<br>
            NICE인증 고객센터 문의 <span style="color: #416dff;">&#9742;1600-1522</span>
          </span>
        </ur-box-container>

      </ur-box-container>

      <!-- 하단 버튼영역 -->
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="mo_button_002" color="normal" shape="border" size="medium" class="ns-btn-round blue" :disabled="authNumberDisabled" @click="fn_PrcsMoblAthntCnf">인증완료</mo-button>
        </div>
      </ur-box-container>
      <!-- 하단 버튼영역 //-->  

      <!-- 바텀시트: 본인확인 서비스 이용동의 체크시 팝업표출 -->
      <mo-bottom-sheet ref="bottomSheet1"  class="ns-bottom-sheet closebtn" @before-close="fn_SheetClose">
       <template v-slot:title>
          본인확인서비스 이용동의
          <div class="ns-btn-close" @click="fn_AgreeCnfrmPop('CloseBtn')" name="닫기"></div>
        </template>
        <div class="content-area">
          <!-- 신규작성 : START -->
          <ul class="terms-list-area pb36">
            <li>개인정보 수집/이용 동의</li>
            <li>고유식별정보 처리 동의</li>
            <li>통신사 이용약관 동의</li>
            <li>서비스 이용약관 동의</li>
            <li>개인정보 제 3자 제공 동의</li>
          </ul>
          <!-- 신규작성 : END -->
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="" color="primary" shape="primary" size="border" @click="fn_AgreeCnfrmPop('Confirm')" class="ns-btn-round white">약관 확인</mo-button>
              <mo-button componentid="" color="primary" shape="primary" size="border" @click="fn_AgreeCnfrmPop('AllConfirm')" class="ns-btn-round blue">전체 약관 동의</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      <!-- 바텀시트: 바텀시트: 본인확인 서비스 이용동의 체크시 팝업표출 //-->    
  
    </ur-box-container>
    <!-- 컨텐츠영역  //-->
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
***********************************************************************************/
import moment from 'moment'
import cmConstants from '@/config/constants/cmConstants'

import MSPCM010P from '@/ui/cm/MSPCM010P' // 알뜰폰 안내

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  // 현재 화면명
  name: 'MSPCM309P', 
  // 현재 화면 ID
  screenId: 'MSPCM309P', 
  props: {
    tempCustCardVo: { // 고객 정보 VO
      type: Object,
      default: {}
    }
  },
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
  ***********************************************************************************/
  data () {
    return {
        lv_UserInfo: {}, // 사용자 정보
        lv_CustInfo: {}, // 고객 정보 VO

        date: 0, // 인증 유효 시간
        now: Math.trunc((new Date()).getTime() / 1000), // 현재시각
        interval: {}, // 인증번호 타이머
        
        lv_TelecomType: '', // 휴대폰 통신사
        prevTelecomType: '', // (temp) 휴대폰 통신사
        telecomTypes: [
          { value: 'SKT', text: 'SKT'},
          { value: 'KTF', text: 'KT' },
          { value: 'LGT', text: 'LG U+' },
          { value: 'SKTM',text: 'SKT(알뜰폰)' },
          { value: 'KTM', text: 'KT(알뜰폰)'  },
          { value: 'LGTM',text: 'LG U+(알뜰폰)'},
          { value: 'MCHK',text: '알뜰폰 확인하기'}
        ],

        phoneItems: [],   // 휴대폰 앞자리 코드
        lv_CelnoFrno: '', // 휴대폰 앞자리 - 선택값

        agreeCheckbox: false, // 본인확인 서비스 이용동의 체크박스
        sCertVnoUrl: 'https://cert.vno.co.kr/app/agree/agree_main.jsp', // 이통사 본인확인 서비스 이용에 따른 동의 나이스 링크 URL

        lv_AuthNumber: '', // 인증번호 - 입력값

        authNumberDisabled: true, // 인증번호 입력란 disabled (default: 비활성화)
        aprvNoErrCnt: 0, // 인증번호 실패 횟수

        isAuthCompleted: false, // 인증성공 여부
        isCountStart: false, // 인증 타이머 시작
        lv_niceAuthObj: {}, // 본인인증 정보

        isDevelopment: (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'stage2' || process.env.NODE_ENV === 'stage'),
    }
  },
  /***********************************************************************************
   * Watch 함수 정의 영역                                                            *
  ***********************************************************************************/
  watch: {
    seconds () {
      if (this.minutes === 0 && this.seconds === 0) {
        this.lv_AuthNumber = ''
        this.authNumberDisabled = true // 인증번호 비활성화
      }
    },
    lv_TelecomType (value) {
      if (value === 'MCHK') { // 알뜰폰 확인하기 선택 시 팝업창 호출 (MSPCM010P 팝업)
        let popObj = this.$bottomModal.open(MSPCM010P, {
          properties: {
            pCustNm: this.lv_CelnoFrno.custNm // 고객명 파라미터
          },
          listeners: {
            confirmPopup: (pData) => {
              this.$bottomModal.close(popObj)
              
              // 파라미터 세팅
              this.fn_ConfirmMSPCM010P(pData)
            }
          }
        });

      }
    }
  },
  /***********************************************************************************
   * Filters 함수 정의 영역                                                          *
  ***********************************************************************************/
  filters: {
    twoDigits: (value) => {
      if (value.toString().length <= 1) {
        return '0' + value.toString()
      }

      return value.toString()
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
  ***********************************************************************************/
  computed: {
    seconds () { // 타이머 초
      let lv_Seconds = (this.remainDateValue - this.now) % 60
      if (lv_Seconds > 0) {
        return (this.remainDateValue - this.now) % 60
      } else {
        if (this.minutes === 0) {
          window.clearInterval(this.interval) // 타이머 중지
        }
        return 0
      }
    },
    minutes () { // 타이머 분
      let lv_Minutes = Math.trunc((this.remainDateValue - this.now) / 60) % 60
      if (lv_Minutes > 0) {
        return Math.trunc((this.remainDateValue - this.now) / 60) % 60
      } else {
        return 0
      }
    },
    remainDateValue () {
      return Math.trunc(this.date / 1000)
    },
    fn_TelecomType: { //통신사
      get: function () {
        return this.lv_TelecomType
      },
      set: function (data) {
        this.prevTelecomType = this.lv_TelecomType // 현재 선택되어 있는 값 복사
        this.lv_TelecomType = data
      }
    },
    // 전체 휴대번호
    fn_FullCelNo() {
      let rtnValu = ''
      
      rtnValu = this.lv_CelnoFrno + this.lv_CustInfo.celBackNo
      return rtnValu;
    }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
  ***********************************************************************************/
  created() {
    console.log('■■■■■■■ MSPCM309P ■■■■■■■')
    this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo

    // 고객 정보 파라미터 정제
    this.lv_CustInfo = this.tempCustCardVo
    this.lv_CustInfo.forigChk = true

    this.fn_Init()
  },
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM309P')
  },
  destroyed() {
    window.clearInterval(this.interval) // 타이머 실행이 아직도 되어 있다면 타이머 중지
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /*********************************************************
     * @function  fn_Init
     * @notice    화면진입시, 최초 실행 함수
     *            초기 셋팅 및 설정
    *********************************************************/
    fn_Init () {
      const lv_Vm = this
      let lv_Item = {}

      // 휴대전화 앞자리 정보 세팅
      // let lv_CelphCD = lv_Vm.getStore('code').getters.getState.get('ZA_CELPH_KNB_CD')
      let defaultValu = {value: '0', text: '선택'}
      let lv_CelphCD = this.$bizUtil.cloneDeep(cmConstants.CELPH_KNB_CD)
      this.phoneItems = [defaultValu, ...lv_CelphCD]

      let celFronNoObj = lv_CelphCD.filter(item => {
          return item.key === lv_Vm.lv_CustInfo.celFronNo
        });

      if (_.isEmpty(celFronNoObj)) {
        // 휴대폰번호 앞자리 초기값 셋팅
        lv_Vm.lv_CelnoFrno = '010'
      } else {
        lv_Vm.lv_CelnoFrno = lv_Vm.lv_CustInfo.celFronNo
      }

      // 필동/마동 체크 여부
      if (this.lv_CustInfo.mndtCnstgCnsntYn === 'Y') {
        if (this.lv_CustInfo.mktPrcusCnsntYn === 'N') { // 필컨만
          this.lv_CustInfo.infoPrcusCnsntTypCd = '17'
        } else { // 둘다
          this.lv_CustInfo.infoPrcusCnsntTypCd = '18'
        }
      } else if (this.lv_CustInfo.mktPrcusCnsntYn === 'Y') { 
        if (this.lv_CustInfo.mndtCnstgCnsntYn === 'N') { // 마동만
          this.lv_CustInfo.infoPrcusCnsntTypCd = '19'
        } else { // 둘다
          this.lv_CustInfo.infoPrcusCnsntTypCd = '18'
        }
      }
    },
    /******************************************************************************
     * @function  fn_Validation
     * @notice    인증번호 버튼 클릭 시 유효성 체크
    ******************************************************************************/
    fn_Validation () {
      // 동의 체크 여부 확인
      // 휴대번호 체크
      let lv_RetVal = true

      if (this.agreeCheckbox === false) {
        this.agreeCheckbox = true // true: 안내창 노출시키기 위함

        // 본인확인 서비스 이용동의 안내창 노출
        this.fn_AgreeCnfrmPop('Open')
        lv_RetVal = false
        return lv_RetVal
      } else if (this.$bizUtil.isEmpty(this.lv_TelecomType) || this.lv_TelecomType === 'MCHK') { // MCHK: 알뜰폰 확인하기
        this.getStore('confirm').dispatch('ADD', '통신사 정보를 확인하세요.')
        lv_RetVal = false
        return lv_RetVal
      } else if (this.$bizUtil.isEmpty(this.lv_CustInfo.celBackNo)) {
        this.getStore('confirm').dispatch('ADD', '휴대폰번호를 입력하세요.')
        lv_RetVal = false
        return lv_RetVal
      }
      
      return lv_RetVal
    },
    /******************************************************************************
     * @function  fn_SelfAuth
     * @notice    인증번호 전송 서비스 호출
    ******************************************************************************/
    fn_SelfAuth () {
      // 입력값 유효성 체크
      let valiValu = this.fn_Validation()
      if (!valiValu) return

      const lv_Vm = this

      let pParam = {
        custNm: this.lv_CustInfo.custNm, // 고객명
        cnsltNo: this.lv_CustInfo.cnsltNo, // 담당 컨설턴트 사번
        chnlCustId: this.lv_CustInfo.chnlCustId, // 채널고객ID

        knb: this.lv_CustInfo.knb.replace('******', '000000'), // 주민등록번호
        infoPrcusCnsntTyoCd:  this.lv_CustInfo.infoPrcusCnsntTypCd, // 정보활용동의유형코드(17:필수컨설팅동의, 19:마케팅동의, 18: 필컨/마동 둘다 선택, 11:전환설계)
        custCelphCmucoNm: this.lv_TelecomType, // 휴대폰통신사
        celno: this.fn_FullCelNo, // 휴대번호

        mktCnsntAvlPrd: '', // 마케팅동의 이용기간 (0~5)
        telReciCnsntYn: '', // 마케팅동의권유방식(전화)
        smsReciCnsntYn: '', // 마케팅동의권유방식(문자)
        emailReciCnsntYn: '', // 마케팅동의권유방식(이메일)
        pstReciRjtYn: '' // 마케팅동의권유방식(우편)
      }

      const trnstId = 'txTSSCM12I0'
      const auth = 'S'
      this.post(lv_Vm, pParam, trnstId, auth)
        .then(res => {
          if (!_.isEmpty(res.body) && res.body.niceAthntRespCd === '0000') {
            let lv_TmpResult = res.body

            // Nice 인증 정보 셋팅
            lv_Vm.lv_niceAuthObj.selfAthntRespUnQVal = lv_TmpResult.selfAthntRespUnQVal
            lv_Vm.lv_niceAuthObj.nicePtptInstId = lv_TmpResult.nicePtptInstId
            lv_Vm.lv_niceAuthObj.lmsDspchRsltId = lv_TmpResult.lmsDspchRsltId

            // 타이머 세팅 및 시작
            lv_Vm.isCountStart = true
            lv_Vm.date = moment(new Date()).add(7, 'm').toDate() // 인증 유효 시간을 7분으로 timer 세팅
            lv_Vm.interval = window.setInterval( () => {
              lv_Vm.now = new Date(Date.now() + (new Date().getTimezoneOffset() * 60000)).getTime() / 1000 | 0
            }, 1000)

            // 인증번호 입력란 포커스
            lv_Vm.$refs['refAuthNumber'].focus()

            lv_Vm.authNumberDisabled = false // 인증번호 Text-Input 활성화
            lv_Vm.aprvNoErrCnt = 0 // 실패 횟수는 다시 0으로 초기화
          }
        })
        .catch(error => {
          window.vue.error(error)
        });

    },
    /******************************************************************************
     * @function  fn_AuthSkip
     * @notice    인증 스킵을 위한 서비스 호출(세션정보에 인증완료 저장)
     ******************************************************************************/
    fn_AuthSkip () {
      const lv_Vm = this

      let pParam = {
        cnsltNo: this.lv_UserInfo.userId,
        moblAthntNo: 'skip'
      }
      const trnstId = 'txTSSCM12I0'
      const auth = 'S'
      this.post(lv_Vm, pParam, trnstId, auth)
        .then(res => {
          if (!_.isEmpty(res.body) && res.body.niceAthntRespCd === '0000') {
            lv_Vm.$emit('onPopupConfirm', 'S')
          }
        })
        .catch(error => {
          window.vue.error(error)
        })

    },
    /******************************************************************************
     * @function  fn_PrcsMoblAthntCnf
     * @notice    인증번호 확인 서비스 호출
    ******************************************************************************/
    fn_PrcsMoblAthntCnf () {
      const lv_Vm = this

      if (this.$bizUtil.isEmpty(this.lv_AuthNumber) || this.lv_AuthNumber.length < 6) {
        this.getStore('confirm').dispatch('ADD', '인증번호 정보가 정확하지 않습니다.') // 인증번호 정보가 정확하지 않습니다.
        return
      }

      let pParams = {
        smsAthntNo: this.lv_AuthNumber, // 입력한 인증번호
        selfAthntRespUnqVal: this.lv_niceAuthObj.selfAthntRespUnQVal, // 본인인증 응답 고유값
        nicePtptInstId: this.lv_niceAuthObj.nicePtptInstId, // 한신정참여기관ID
        lmsDspchRsltId: this.lv_niceAuthObj.lmsDspchRsltId, // LMS발송결과ID
        aprvNoErrCnt: this.aprvNoErrCnt,
      }

      const trnstId = 'txTSSCM12S0'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then((res) => {
          this.aprvNoErrCnt++ // 인증번호 전송 횟수 체크 (3회까지만 허용됨)

          if (!_.isEmpty(res.body) && res.body.niceAthntRespCd === '0000') { // 인증 확인 처리
            window.clearInterval(lv_Vm.interval) // 타이머 종료
            lv_Vm.isCountStart = false
            lv_Vm.isAuthCompleted = true

            // 본인인증 팝업 닫기
            this.$emit('onPopupConfirm', 'S')
          } else { // 정상처리가 아닌경우
            lv_Vm.getStore('confirm').dispatch('ADD', res.msgComm.msgDesc)

            if (res.msgComm.msgCd === 'ECMU001') { // 인증번호 3회 오류 시
              window.clearInterval(lv_Vm.interval) // 타이머 종료
              lv_Vm.isCountStart = false
            }
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        });

    },
    /******************************************************************************
     * @function  fn_AgreeCnfrmPop
     * @notice    안내 팝업 호출
    ******************************************************************************/
    fn_AgreeCnfrmPop (param) {
      switch (param) {
        case 'Open':
          if(this.agreeCheckbox){
            this.agreeCheckbox = false
            this.$refs.bottomSheet1.open()
          }
          break
        case 'CloseBtn':
          this.$refs.bottomSheet1.close()
          // this.fn_OnNegative()
          break
        case 'Confirm': // 약관 확인
          this.$refs.bottomSheet1.close('S')
          this.fn_AgreeConfirmYes() // 동의 나이스 링크 호출
          break
        case 'AllConfirm': // 전체 약관 확인
          this.$refs.bottomSheet1.close('S')
          // this.fn_OnNegative()
          break
      }
    },

    /******************************************************************************
    * @function  fn_ConfirmMSPCM010P
    * @notice    알뜰폰 안내 팝업창의 '확인', '취소' 버튼 클릭 시 호출
    ******************************************************************************/
    fn_ConfirmMSPCM010P (param) {
      console.log('CM010P>> ', param)
      if (param !== null && param !== '') {
        this.lv_TelecomType = param.val
      } else { // 취소버튼 클릭 시
        this.lv_TelecomType = this.prevTelecomType
      }
    },

    /******************************************************************************
    * @function  fn_AgreeConfirmYes
    * @notice    본인확인 서비스 이용 안내 confirm팝업창의 '동의' / '동의안함' 버튼 선택 시
    ******************************************************************************/
    fn_AgreeConfirmYes () {
        this.agreeCheckbox = true
        this.fn_AgreePopContent()
    },
    /******************************************************************************
    * @function  fn_AgreePopContent
    * @notice    이통사 본인확인 서비스 이용에 따른 동의 나이스 링크 호출
    ******************************************************************************/
    fn_AgreePopContent () {
      let lv_Url = this.sCertVnoUrl
      if (this.agreeCheckbox) {
        if (this.$commonUtil.isMobile()) { // 모바일에서 작동
          window.fdpbridge.exec('intentBrowserPlugin', {uri: lv_Url}, () => {}, () => {})
        } else { // PC에서 작동
          window.open(lv_Url, '_blank')
        }
      }
    },
    /******************************************************************************
    * @function  fn_SheetClose
    * @param fn_CloseSheet: BottomSheet Close Function
    * @param item: BottomSheet Event Name
    * 
    * @notice    본인확인서비스 이용동의 안내 Close Fn
    *           - 약관동의로 Close / 강제로 Close 구분 목적
    * @notice 강제로 / X버튼으로 닫을시 Event Name = 'swipe'
    *         - 약관 동의하게되면 item = 'S' 
    ******************************************************************************/
    fn_SheetClose(fn_CloseSheet, item) {
      this.agreeCheckbox = item === 'swipe' ? false : true
      fn_CloseSheet()
    },
  }
};
</script>
