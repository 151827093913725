 /*
 * 업무구분 : 고객홈
 * 화 면 명 : MSPCM303D
 * 화면설명 : 고객카드 수정 - 세대관계정보
 * 작 성 일 : 2023.02.16
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-page-container class="msp" title="세대관계정보" :show-title="false" :topButton="false">
    <ur-box-container alignV="start" componentid="" direction="column" class="mb80">
      <ur-box-container alignV="start"  direction="row" class="pal1224 pt20 pb20 bd-b-Ty1">
        <mo-button color="normal" shape="border" size="large" class="blue flex-1 mr5" @click="fn_AddChild">태아등재</mo-button>
        <mo-button color="normal" shape="border" size="large" class="blue flex-1 ml5" @click="fn_OpenMSPCM304P()">세대조정</mo-button>
      </ur-box-container>
      <ur-box-container v-if="fn_CmptdHoshTot > 0" alignV="start" componentid="" direction="column" class="ns-check-list type-6"> <!-- 2023.05.09 'type-6' 추가 -->
        <mo-list :list-data="lv_CustHoshListVO">
          <template #list-item="{item}">
            <mo-list-item class="pt5 pb10">
              <div class="list-item__contents closebtn">
                <div class="list-item__contents__title fexTy3">
                  <span class="name txtSkip">{{ item.custNm }}</span>
                  <!-- 2023.05.09 뱃지 -> 셀렉트박스로 수정 -->
                  <span class="fexTy3">
                    <msp-bottom-select :items="mnCustRltnItems" v-model="item.mnCustRltnCd" class="ns-dropdown-sheet bd-radius-6 bdTy12" bottom-title="주고객관계" placeholder="선택" closeBtn scrolling
                                        :class="{
                                          'select-1' : fn_CmptdSelfCustTot < 2 && item.mnCustRltnCd == '01',
                                          'error' : fn_CmptdSelfCustTot > 1 && item.mnCustRltnCd == '01',
                                        }"
                                        :itemValue="'key'" :itemText="'label'" :disabled="item.custNm==='태아'"/>
                    <mo-icon icon="close" class="fs18rem crTy-bk9 ml10" @click="fn_DeltAlert(item)"></mo-icon>
                  </span>
                  <!-- 2023.05.09 뱃지 -> 셀렉트박스로 수정 -->

                  <!-- 2023.05.09 뱃지 삭제 -->
                  <!-- <mo-badge v-if="item.knbScCd === 'ZPER02'" class="badge-sample-badge purple sm" text="외국인" shape="status">외국인</mo-badge> -->
                  <!-- <mo-badge class="badge-sample-badge lightgray2 sm ml10" text="내국인" shape="status">{{ fn_CustRltnNm(item.mnCustRltnCd) }}</mo-badge> -->
                  <!-- <mo-icon icon="close" class="fs18rem crTy-bk9 ml10" @click="fn_DeltAlert(item)"></mo-icon> -->
                </div>
                <div class="list-item__contents__info">
                  <span class="crTy-bk1">{{ $cmUtil.insertString(item.knb, 6, '-') }}</span>
                </div>
              </div>
            </mo-list-item>
          </template>
        </mo-list>   
      </ur-box-container>
      <!-- NoData 영역: start  -->
      <ur-box-container v-else alignV="start" componentid="ur_box_container_002" direction="column" class="ns-contract-list no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      <!-- NoData 영역: end  -->
    </ur-box-container>
   
  </ur-page-container>
</template>

<script>
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import cmConstants from '~/src/config/constants/cmConstants'

import MSPCM304P from '@/ui/cm/MSPCM304P.vue'
export default {

  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  // 현재 화면명
  name: 'MSPCM303D', 
  // 현재 화면 ID
  screenId: 'MSPCM303D', 
  /***********************************************************************************
   * 화면 UI Property 정의
  ***********************************************************************************/
  props:{
    pCustInfo: {
      type: Object,
      default: {}
    },
    getDataCall: {
      type: Boolean,
      default: false
    },
    pAddChild: {
      type: Boolean,
      default: false
    }
  },
  /***********************************************************************************
   * 화면 전역 변수 선언 영역
  ***********************************************************************************/
  data () {
    return {
      title: '세대관계정보',
      lv_UesrInfo: {}, // 사용자 정보

      lv_RsltBasSVO: {}, // 고객 상세정보
      lv_CustHoshListVO: [], // 고객 세대원 리스트
      lv_EditCustHoshList: [], // 변경된 세대원 리스트

      lv_Alert: {} // 알럿 Object
    };
  },
  /***********************************************************************************
   * watch 정의 영역                                                                 *
  ***********************************************************************************/
  watch: {
    'pCustInfo.lv_RsltBasSVO'() {
      this.lv_RsltBasSVO = this.$bizUtil.cloneDeep(this.pCustInfo.lv_RsltBasSVO)
      if (!_.isEmpty(this.lv_RsltBasSVO)) {
        this.lv_CustHoshListVO = this.$bizUtil.cloneDeep(this.lv_RsltBasSVO.iCHoshInfoListInqrVO)

        if (this.lv_RsltBasSVO.addChild) {
          this.$nextTick(_ => {
            this.fn_AddChild()
          })
          
        }
      }
    },
    getDataCall() {
      this.fn_CallbackData()
    }
  },
  computed: {
    fn_CmptdSelfCustTot() {
      let hoshList = [this.lv_RsltBasSVO, ...this.lv_CustHoshListVO]
      
      let custSelfList = hoshList.filter(item => {
          return item.mnCustRltnCd === '01'
        });

      return custSelfList ? custSelfList.length : 0
    },
    fn_CmptdHoshTot() {
      return this.lv_CustHoshListVO ? this.lv_CustHoshListVO.length : 0
    },
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  created() {
    console.log('■■■■■■■ MSPCM303D ■■■■■■■')
  },
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM303D')

    this.fn_Init()
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    fn_Init() {
      this.mnCustRltnItems = [...cmConstants.CUST_RLTN_CD, {key: '08', label: '기타가족'}]
    },
    /*********************************************************
     * @function fn_CustRltnNm
     * @notice   세대원-주고객관계 (화면 바인딩용)
    *********************************************************/
    fn_CustRltnNm (rltnCd) {
      let rltnItemList = [...cmConstants.CUST_RLTN_CD, {key: '08', label: '기타가족'}]

      let custRltnObj = rltnItemList.filter(item => {
          return item.key === rltnCd
        })[0];
      
      return custRltnObj ? custRltnObj.label : ''
    },
    /*********************************************************
     * @function fn_AddChild
     * @name     태아등재_이벤트
     * @notice   태아는 2명이상 등록 불가
     *           (태아 정보 As-Is와 동일한 값)
    *********************************************************/
    fn_AddChild () {
      const lv_Vm = this

      let addObj = {
        prcsfCd: "I", // 프로세스 falg 코드
        cnsltNo: this.lv_RsltBasSVO.cnsltNo,// 담당 컨설턴트 사번
        custNm: "태아",
        custId: "",
        chnlCstgrId: "00000000000000000000",
        chnlCustId: "00000000000000000000",

        chnlCustScCd: "02", // 고객속성코드
        chnlCustScCdNm: "임시고객",
        custJobCd: "116100", // 직업코드
        custJobCdNm: "미취학아동",
        mnCustRltnCd: "03", // 주고객 관계 코드

        knb: "0000003000000", // 주민등록번호 (As-Is와 동일)
        dob: "00000000",
        knbScCd: "ZPER01", // 국내 코드
      }

      let IsChild = this.lv_CustHoshListVO.filter(item => {
          return item.custNm === '태아'
        });

      if (IsChild.length < 1) {
        this.lv_CustHoshListVO.push(addObj)
        this.lv_EditCustHoshList = [addObj]
      } else {
        let alertMsg = '태아는 한명만 등록 가능합니다.'
        this.fn_ShowBottomAlert(alertMsg)
      }
    },
    /*********************************************************
     * @function fn_DeltAlert
     * @name     세대원_삭제_컨펌
     * @notice   선택한 세대원의 고객카드를 삭제하는것을 확인
    *********************************************************/
    fn_DeltAlert (selItem) {
      const lv_Vm = this
      this.$bottomModal.close(this.lv_Alert)

      let alertMsg = `
                      해당 고객의 정보와 활동이력이 모두 삭제됩니다.<br /><br />
                      세대분리를 원하시면 취소 후 세대조정 버튼을, 모든 정보의 삭제를 원하시면 삭제버튼을 누르세요.
                    `

      this.lv_Alert = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          content: alertMsg,
          title_pos_btn: '삭제',
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(this.lv_Alert)
            lv_Vm.fn_SuitaDeltHosh(selItem)
          },
          onPopupClose: () => {
            this.$bottomModal.close(this.lv_Alert)
          }
        }
      });

    },
    /*********************************************************
     * @function fn_SuitaDeltHosh
     * @name     세대원_삭제_가능여부_체크
     * @notice   주고객/세대주는 삭제 불가
    *********************************************************/
    fn_SuitaDeltHosh (selItem) {
      let sMnCustRltnCd = selItem.mnCustRltnCd
      let sHoshMnYn = selItem.hoshMnYn
      let alertMsg = ''
      
      // (세대원 정보 목록 중) 주고객을 삭제하려는 경우, 삭제불가 경고
      if (!this.$bizUtil.isEmpty(sMnCustRltnCd) && sMnCustRltnCd === '01') {
        alertMsg = '선택된 세대원(주고객)은 삭제할 수 없습니다.'
      }

      // (세대원 정보목록 중) 그외 세대원(세대주)을 삭제하려는 경우, 삭제불가 경고
      if (sHoshMnYn === 'X') {
        alertMsg = '선택된 세대원(세대주)은 삭제할 수 없습니다.'
      }
      
      if (!this.$bizUtil.isEmpty(alertMsg)) {
        window.setTimeout(() => {
          this.fn_ShowBottomAlert(alertMsg)
        }, 500)
        return
      }

      // 세대원 고객카드 삭제 함수 호출
      this.fn_DeltHoshItem(selItem)
    },
    /*********************************************************
     * @function fn_DeltHoshItem
     * @name     고객카드_삭제
     * @notice   선택한 세대원의 고객카드 삭제한다.
    *********************************************************/
    fn_DeltHoshItem (selItem) {
      const lv_Vm = this

      if (selItem.custId === '') {
        let custHoshList = lv_Vm.lv_CustHoshListVO.filter(item => {
            return item.custId !== selItem.custId
          });
      
        lv_Vm.lv_CustHoshListVO = [...custHoshList]
        return
      }

      let pParam = selItem
      pParam.prcsfCd = "D" // 삭제 Flag CD

      const trnstId = 'txTSSCM11D0'
      const auth = 'D'
      this.post(lv_Vm, pParam, trnstId, auth)
        .then(res => {
          if (!_.isEmpty(res.body) && !this.$bizUtil.isEmpty(res.body.chnlCustId)) {
            let custHoshList = lv_Vm.lv_CustHoshListVO.filter(item => {
                return item.chnlCustId !== res.body.chnlCustId
              });
      
            lv_Vm.lv_CustHoshListVO = [...custHoshList]
            this.$emit('setCM303D', lv_Vm.lv_CustHoshListVO)
          }
        })
        .catch(error => {
          window.vue.error(error)
        });

    },
    /*********************************************************
     * @function fn_ValidateHosh
     * @name     세대조정_적합성체크
     * @notice   세대원 수정된 주고객관계 적합성 체크한다
     *           - 본인 2명 존재 불가, 배우자 2명 존재 불가
    *********************************************************/
    fn_ValidateHosh () {
      const lv_Vm = this
      let alertMsg = ''

      let hoshList = [this.lv_RsltBasSVO, ...this.lv_CustHoshListVO]

      let selfList = hoshList.filter(item => {
          return item.mnCustRltnCd === '01'
        })

      if (selfList.length > 1) {
        // 본인 2명 존재 불가
        alertMsg = "주고객관계 '본인'은 1명씩만 있어야 합니다."
        this.fn_ShowBottomAlert(alertMsg)
        return false
      }

      let spuList = hoshList.filter(item => {
          return item.mnCustRltnCd === '02'
        });

      if (spuList.length > 1) {
        alertMsg = "배우자 2명이상으로 저장이 불가능합니다."
        this.fn_ShowBottomAlert(alertMsg)
        return false
      }

      return true
    },
    /*********************************************************
     * @function fn_OpenMSPCM304P
     * @name     팝업호출
     * @notice   세대조정 팝업 호출
    *********************************************************/
    fn_OpenMSPCM304P () {
      const lv_Vm = this

      let popObj = this.$bottomModal.open(MSPCM304P, {
        properties: {
          pCustInfo: lv_Vm.pCustInfo.lv_RsltBasSVO
        },
        listeners: {
          onPopupConfirm: () => {
            // 고객정보 - 기본정보 재조회
            this.$emit('callInit')
            this.$bottomModal.close(popObj)
          },
          onPopupClose: () => {
            this.$bottomModal.close(popObj)
          }
        }
      });

    },
    /*********************************************************
     * @function fn_ShowBottomAlert
     * @name     BottomAlert_Component_호출_함수
     * @param {String} alertMsg: 알럿 메시지
    *********************************************************/
    fn_ShowBottomAlert (alertMsg) {
      this.$bottomModal.close(this.lv_Alert)

      this.lv_Alert = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          content: alertMsg
        },
        listeners: {
          onPopupConfirm: () => {
            // 확인 버튼
            this.$bottomModal.close(this.lv_Alert)
          }
        }
      });

    },
    /*********************************************************
     * @function fn_CallbackData
     * @notice   부모 화면(MSPCM300P)에 세대우 변경사항 리턴한다
     *           관계 변경시, 입력구분코드(prcsfCd) 'U'
     *           태아 추가시, 입력구분코드(prcsfCd) 'I'
    *********************************************************/
    fn_CallbackData () {
      const lv_Vm = this

      let orgHoshInfoList = this.lv_RsltBasSVO.iCHoshInfoListInqrVO
      
      if (!_.isEmpty(orgHoshInfoList)) {
        orgHoshInfoList.forEach(item => {
          for (let i=0; i<lv_Vm.lv_CustHoshListVO.length; i++) {
            if (item.chnlCustId === lv_Vm.lv_CustHoshListVO[i].chnlCustId) {
              if (item.mnCustRltnCd !== lv_Vm.lv_CustHoshListVO[i].mnCustRltnCd) {
                  lv_Vm.lv_CustHoshListVO[i].prcsfCd = 'U'
              }
            }
          }
        })

      }

      let rtnList = this.lv_CustHoshListVO
      // let rtnList = this.lv_CustHoshListVO.filter(item => {
      //     return item.prcsfCd === 'I' || item.prcsfCd === 'U'
      //   })

      this.$emit('setCM303D', rtnList)
    },
  } // ::Methods End
};
</script>
