/*
 * 업무구분 : 세대조정
 * 화 면 명 : MSPCM308P
 * 화면설명 : 세대조정 - 다른세대 - 고객선택
 * 작 성 일 : 2023.03.16
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-page-container title="고객선택" :show-title="true" type="subpage" class="msp">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      
      <!-- 검색결과 있을 경우 -->
      <ur-box-container v-if="fn_CmptdCustTot > 0" alignV="start" componentid="" direction="column" class="ns-radio-list2 customer-list-ty mb82">
        <mo-list :list-data="lv_CustInfoList">
          <template #list-item="{item}">
            <mo-list-item :class="{'on' : lv_SelCust === item.chnlCustId}">

              <!-- 라디오 버튼 영역 -->
              <mo-radio v-model="lv_SelCust" :value="item.chnlCustId" @input="fn_SelectItem(item)"></mo-radio>

              <!-- 체크박스 영역 확인 필요.. -->
              <div class="list-item__contents" @click="fn_SelectItem(item)">
                <div class="list-item__contents__title">
                  <!-- 고객명 -->
                  <span class="name txtSkip">{{ item.custNm }}</span>

                  <!-- 고객구분 -->
                  <mo-badge class="badge-sample-badge lightblue sm" text="본인" shape="status">{{ item.chnlCustScCdNm }}</mo-badge> 
                </div>
                <div class="list-item__contents__info">
                  <span>
                    <!-- 생년월일 -->
                    <span class="crTy-bk1 ml0">{{ $commonUtil.dateDashFormat(item.dob) }}</span><em>|</em>
                    <!-- 성별 -->
                    <span class="crTy-bk1 ml0">{{ item.sxdsCdNm }}</span>
                  </span><em>|</em>
                  <span>
                    <!-- 주고객명 -->
                    <span class="crTy-bk7 fs14rem ml0">주고객</span>
                    <span class="crTy-bk1 ml10">{{ item.mnCustNm }}</span>
                  </span>
                </div>
                <div class="list-item__contents__info">
                  <span>
                    <span class="crTy-bk7 ml0 fs14rem">관계</span>
                    <span class="crTy-bk1 ml10">{{ item.mnCustRltnCdNm }}</span>
                  </span>
                  <span v-if="!$bizUtil.isEmpty(item.celno)">
                    <em>|</em>
                    <span>
                      <span class="crTy-bk7 fs14rem ml0">연락처</span>
                      <span class="crTy-bk1">{{ item.celno }}</span>
                    </span>
                  </span>
                </div>
              </div>
            </mo-list-item>
          </template>
        </mo-list>   
      </ur-box-container>

      <!-- 검색 결과 없을 경우 -->
      <ur-box-container v-else alignV="start" componentid="ur_box_container_002" direction="column" class="ns-contract-list no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_BtnCancel">취소</mo-button>
          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_BtnCnfm"
                      :disabled="fn_CmptdCustTot < 1 || $bizUtil.isEmpty(lv_SelCust)">확인
          </mo-button>
        </div>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  import cmConstants from '~/src/config/constants/cmConstants';
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCM308P",
    screenId: "MSPCM308P",
    components: {},
    props: {
      pCustNm: {
        type: String,
        default: ''
      },
      pCustCstgrId: {
        type: String,
        default: ''
      },
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      console.log('■■■■■■■ MSPCM308P ■■■■■■■')
      this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo.userId

      this.fn_Init()
    },
    mounted() {
      // 화면접속로그 등록(필수)
      this.$bizUtil.insSrnLog('MSPCM308P')
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        stndKeyList: [],
        lv_SelCust: '', // 선택한 고객
        
        lv_CustInfoList: [], // 고객 리스트
        lv_UserInfo: {},     // 사용자 정보
        lv_SlctCustVO: {},   // 선택 고객 정보
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      fn_CmptdCustTot() {
        return this.lv_CustInfoList ? this.lv_CustInfoList.length : 0
      },
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /*********************************************************
       * @function fn_Init
       * @notice   화면진입시, 최초 실행 함수
       *           초기 셋팅 및 설정
      *********************************************************/
      fn_Init () {
        const lv_Vm = this
        this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo

        this.fn_SelListCust()
      },
      /*********************************************************
       * @function fn_SelectItem
       * @notice   고객 선택 이벤트 함수
      *********************************************************/
      fn_SelectItem (item) {
        this.lv_SelCust = item.chnlCustId
        this.lv_SlctCustVO = item
      },
      /*********************************************************
       * @function fn_SelListCust
       * @notice   고객명 조회 서비스 함수
      *********************************************************/
      fn_SelListCust () {
        const lv_Vm = this
        let custNm = this.pCustNm ? this.pCustNm.trim() : ''

        let pParams = {
          cnsltNo: this.lv_UserInfo.userId, // 사용자 사번
          custNm: custNm // 고객명
        }
        // GA 매니저 대행 컨설턴트 변경시
        if(this.$cmUtil.fn_GachangeTF()){
          pParams.cnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
        }
      
        const trnstId = 'txTSSCM33S2'
        const auth = 'S'
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(res => {
            let custList = res.body.iCCustInfoVO
            let resCnsltNo = res.body.cnsltNo

            // 조회한 고객 중 주고객과 동일한 채널카테고리ID는 미노출한다.
            lv_Vm.lv_CustInfoList = custList.filter(item => {
                item.cnsltNo = resCnsltNo // 컨설턴트 사번
                return item.chnlCstgrId !== lv_Vm.pCustCstgrId // 주고객 채널카테고리ID
              });

          })
          .catch(error => {
            window.vue.error(error)
          });

      },
      /*********************************************************
       * @function fn_CustTypeNm
       * @notice   고객 관계 셋팅
      *********************************************************/
      fn_CustTypeNm (custTypeCd) {
        let custRltnObj = cmConstants.CUSTOMER_DETAIL_TYPE_CD.filter(item => {
            return item.key === custTypeCd
          })[0];
        
        return custRltnObj ? custRltnObj.label : ''
      },
      /*********************************************************
       * @function fn_lv_SelCustCardInfo
       * @name     고객정보조회
       * @notice   선택한 고객 정보 조회후, 팝업 닫기
      *********************************************************/
      fn_lv_SelCustCardInfo () {
        const lv_Vm = this

        let pParams = {
          conctScCd: "1",
          whlInqrYn: "Y",
          chnlCustId: this.lv_SlctCustVO.chnlCustId,
          cnsltNo: this.lv_SlctCustVO.cnsltNo
        }
        // GA 매니저 대행 컨설턴트 변경시
        if(this.$cmUtil.fn_GachangeTF()){
          pParams.cnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
        }
      
        //매니저가 컨설턴트를 변경했을경우
        this.stndKeyList = []
        if(this.$cmUtil.fn_GachangeTF()){
          this.stndKeyList.push({stndKeyId: 'cnsltNo', stndKeyVal: this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo})
        }

        const trnstId = 'txTSSCM11S1'
        const auth = 'S'
        // 고객상세인적사항 정보
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(res => {
            if (lv_Vm.$commonUtil.isSuccess(res)) {
              // 서비스 기본 후처리 (서비스 호출 결과(body)가 있을 경우)
              if (res.body !== null && res.body !== '') {
                // 서버 데이터 가져오기
                let hoshList = [res.body, ...res.body.iCHoshInfoListInqrVO]
                let pParam = {
                  pHoshList: hoshList,
                  pChnlCustId: lv_Vm.lv_SelCust
                }

                lv_Vm.$emit('onPopupConfirm', pParam)
              } else { // 고객카드 정보가 없거나 변경, 삭제된 경우
                // 대면용을 제외한 업무용 UI 퍼플 색상 적용 요청 (requested by 황고은 선임), modified by anarchi, 2018.12.20
                // lv_Vm.getStore('confirm').dispatch('ADD', '삭제된 고객이거나 해당 고객카드 정보가 없습니다.')
              }
            } else { // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
              // lv_Vm.getStore('confirm').dispatch('ADD', res.msgComm.msgDesc)
            }
          });

      },
      /*********************************************************
       * @function fn_BtnCnfm_/_fn_BtnCancel
       * @notice   확인버튼/취소버튼 이벤트
      *********************************************************/
      fn_BtnCnfm () {
        this.fn_lv_SelCustCardInfo()
      },
      fn_BtnCancel () {
        this.$emit('onPopupClose')
      },
    }
  };
</script>
