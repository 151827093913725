/*
 * 업무구분 : 세대조정
 * 화 면 명 : MSPCM307D
 * 화면설명 : 세대조정 - 다른세대 - 고객검색
 * 작 성 일 : 2023.03.16
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-page-container title="세대조정" :show-title="false" type="subpage" class="msp">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area">
        <!-- 고객명 조회하기 -->
        <ur-box-container alignV="start" componentid="" direction="column" class="sch-box">
          <mo-text-field ref="iCustNm" class="form-input-name" underline clearable placeholder="이동할 세대의 주고객 입력해주세요." v-model="lv_iCustNm" :rules="validateRuleRequire"
                         @input="lv_iCustNm = lv_iCustNm.toUpperCase()"/>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>

    <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
      <div class="relative-div">
        <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_OpenMSPCM308P">조회하기</mo-button>
      </div>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
import MSPCM308P from '@/ui/cm/MSPCM308P' // 고객선택

/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
***********************************************************************************/
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  // 화면명
  name: "MSPCM307D",
  // 화면ID
  screenId: "MSPCM307D",
  /***********************************************************************************
   * 화면 UI Property 정의
  ***********************************************************************************/
  props: {
    pCustInfo: {
      type: Object,
      default: {}
    },
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      lv_iCustNm: '', // 고객명 - 입력값
      lv_RsltBasSVO: {}, // 주고객 정보 VO

      validateRuleRequire: [
        v => !!v || '필수입력 항목입니다.'
      ],
    };
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
  ***********************************************************************************/
  created() {
    console.log('■■■■■■■ MSPCM307D ■■■■■■■')
    
    this.fn_Init()
  },
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM307D')

    // 화면 진입시, 키패드 활성화
    this.$refs['iCustNm'].focus()
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
  ***********************************************************************************/
  methods: {
    /*********************************************************
     * @function fn_Init
     * @notice   화면진입시, 최초 실행 함수
     *           초기 셋팅 및 설정
    *********************************************************/
    fn_Init () {
      // 화면 Props 셋팅
      if (!_.isEmpty(this.pCustInfo)) {
        this.lv_RsltBasSVO = this.pCustInfo.lv_RsltBasSVO
      }
    },
    /*********************************************************
     * @function fn_OpenMSPCM308P
     * @name     팝업호출
     * @notice   고객검색 팝업 호출
    *********************************************************/
    fn_OpenMSPCM308P () {
      if (this.$bizUtil.isEmpty(this.lv_iCustNm)) return
      
      let popObj = this.$bottomModal.open(MSPCM308P, {
        properties: {
          pCustNm: this.lv_iCustNm,  // 고객명 - 입력값
          pCustCstgrId: this.lv_RsltBasSVO.chnlCstgrId // 주고객 채널카테고리ID
        },
        listeners: {
          onPopupConfirm: (pData) => {
            this.$bottomModal.close(popObj)
            this.$emit('searchCallback', pData)
          },
          onPopupClose: () => {
            this.$bottomModal.close(popObj)
          }
        }
      });

    },
  }
};
</script>
