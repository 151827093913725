/*
 * 업무구분 : 세대조정
 * 화 면 명 : MSPCM305D
 * 화면설명 : 세대조정 - 단독세대
 * 작 성 일 : 2023.03.16
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-page-container title="세대조정" :show-title="false" type="subpage" class="msp">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <ur-box-container alignV="start" componentid="" direction="column">
        <ur-box-container alignV="start" componentid="" direction="row" class="info-title-wrap pal1224 fexTy3 box-shadow-type-1 bgcolor-11"> <!-- 2023.05.03 'bgcolor-11' 추가 //-->
          <div class="con-title3 crTy-white"> <!-- 2023.05.03 'crTy-white' 추가 //-->
            <span>현재 세대</span>
          </div>
        </ur-box-container>
        <!-- 현재 세대 -->
        <ur-box-container alignV="start" componentid="" direction="column" id="nsList01" class="ns-list-area pt10 pb0 pl10 pr10 bgcolor-1 tbl_cnt overflow-scroll-y">
          <ur-box-container alignV="start" componentid="" direction="column" id="nsList011" class="ns-check-list type-5">
            <div v-for="(item,idx) in lv_NowHoshList" :key="idx" class="list-item w100 bdTy10 mb10">
              <div class="list-item__contents">
                <div class="list-item__contents__title fexJsCtTy1">
                   <!-- 고객명 -->
                  <span class="name txtSkip fexInt">{{ item.custNm }}</span>
                  <mo-button color="normal" shape="border" size="small" class="black bd-radius-3 bdTy12-2 pl6 pr6" @click="fn_HoshHandler('move', item)">▼</mo-button>
                </div>
                <div class="list-item__contents__info">
                  <div class="info_box fexTy5">
                    <!-- 생년월일 -->
                    <span>{{ $bizUtil.dateDashFormat(item.dob) }}</span><em>|</em>
                    <!-- 성별 -->
                    <span v-if="item.custNm !== '태아' && (item.sxdsCd === '1' || item.sxdsCd === '2')">
                      {{ item.sxdsCd === '1' ? '남' : '여' }}
                    </span>
                    <span v-if="item.custNm !== '태아' && (item.sxdsCd === '3' || item.sxdsCd === '4')">
                      {{ item.sxdsCd === '3' ? '남' : '여' }}
                    </span>
                    <span v-if="item.custNm !== '태아' && (item.sxdsCd === '5' || item.sxdsCd === '6')">
                      {{ item.sxdsCd === '5' ? '남' : '여' }}
                    </span>
                    <span v-if="item.custNm !== '태아' && (item.sxdsCd === '7' || item.sxdsCd === '8')">
                      {{ item.sxdsCd === '7' ? '남' : '여' }}
                    </span>
                    <!-- 주고객관계 Select Box -->
                    <msp-bottom-select :items="lv_CustRltnList" v-model="item.mnCustRltnCd" class="ns-dropdown-sheet w100px ml16 bd-radius-6 bdTy12"
                                        :class="{
                                          'select-1' : fn_CmptdNowCustTot < 2 && item.mnCustRltnCd == '01',
                                          'error' : fn_CmptdNowCustTot > 1 && item.mnCustRltnCd == '01' || fn_CmptdNowCustTot < 1
                                        }"
                                        :disabled="item.custNm === '태아'"
                                        placeholder="선택" bottom-title="주고객관계" closeBtn />
                  </div>
                </div>
              </div>
            </div>
          </ur-box-container>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="row" class="info-title-wrap pal1224 fexTy3 box-shadow-type-2 bgcolor-11"> <!-- 2023.05.03 'bgcolor-11' 추가 //-->
          <div class="con-title3 crTy-white"> <!-- 2023.05.03 'crTy-white' 추가 //-->
            <span>이동할 세대</span>
          </div>
        </ur-box-container>
        <!-- 이동할 세대 -->
        <ur-box-container alignV="start" componentid="" direction="column" id="nsList02" class="ns-list-area pt10 pb0 pl10 pr10 mb22 bgcolor-1 tbl_cnt overflow-scroll-y">
          <ur-box-container alignV="start" componentid="" direction="column" id="nsList021" class="ns-check-list type-5">
            <div v-for="(item,idx) in lv_MoveHoshList" :key="idx" class="list-item w100 bdTy10 mb10">
              <div class="list-item__contents">
                <div class="list-item__contents__title fexJsCtTy1">
                   <!-- 고객명 -->
                  <span class="name txtSkip fexInt">{{ item.custNm }}</span>
                  <mo-button color="normal" shape="border" size="small" class="black bd-radius-3 bdTy12-2 pl6 pr6" @click="fn_HoshHandler('now', item)">▲</mo-button>
                </div>
                <div class="list-item__contents__info">
                  <div class="info_box fexTy5">
                    <!-- 생년월일 -->
                    <span>{{ $bizUtil.dateDashFormat(item.dob) }}</span><em>|</em>
                    <!-- 성별 -->
                    <span v-if="item.custNm !== '태아' && (item.sxdsCd === '1' || item.sxdsCd === '2')">
                      {{ item.sxdsCd === '1' ? '남' : '여' }}
                    </span>
                    <span v-if="item.custNm !== '태아' && (item.sxdsCd === '3' || item.sxdsCd === '4')">
                      {{ item.sxdsCd === '3' ? '남' : '여' }}
                    </span>
                    <span v-if="item.custNm !== '태아' && (item.sxdsCd === '5' || item.sxdsCd === '6')">
                      {{ item.sxdsCd === '5' ? '남' : '여' }}
                    </span>
                    <span v-if="item.custNm !== '태아' && (item.sxdsCd === '7' || item.sxdsCd === '8')">
                      {{ item.sxdsCd === '7' ? '남' : '여' }}
                    </span>
                    <!-- 주고객관계 Select Box -->
                    <msp-bottom-select :items="lv_CustRltnList" v-model="item.mnCustRltnCd" class="ns-dropdown-sheet w100px ml16 bd-radius-6 bdTy12"
                                        :class="{
                                          'select-1' : fn_CmptdMoveCustTot < 2 && item.mnCustRltnCd == '01',
                                          'error' : fn_CmptdMoveCustTot > 1 && item.mnCustRltnCd == '01' || fn_CmptdMoveCustTot < 1
                                        }"
                                        :disabled="item.custNm === '태아'"
                                        placeholder="선택" bottom-title="주고객관계" closeBtn/>
                  </div>
                </div>
              </div>
            </div>
          </ur-box-container>
        </ur-box-container>
      </ur-box-container>

      <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 -->
      <div class="ns-height60"></div>

    </ur-box-container>
  </ur-page-container>
</template>
<script>
import cmConstants from '~/src/config/constants/cmConstants'

/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
***********************************************************************************/
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  // 화면명
  name: "MSPCM305D",
  // 화면ID
  screenId: "MSPCM305D",
  /***********************************************************************************
   * 화면 UI Property 정의
  ***********************************************************************************/
  props: {
    pCustInfo: {
      type: Object,
      default: {}
    },
    getDataCall: {
      type: Boolean,
      default: false
    }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
  ***********************************************************************************/
  created() {
    console.log('■■■■■■■ MSPCM305D ■■■■■■■')

    this.fn_Init()
  },
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM305D')
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
  ***********************************************************************************/
  data () {
    return {
      lv_RsltBasSVO: {},   // 고객정보 VO
      lv_HoshList: [],     // 세대원 리스트
      lv_NowHoshList: [],  // 현재 세대원 리스트
      lv_MoveHoshList: [], // 이동할 세대원 리스트

      lv_CustRltnList: [], // 주고객 관계 - 선택값
    }
  },
  /***********************************************************************************
   * Watch 함수 정의 영역                                                            *
  ***********************************************************************************/
  watch: {
    getDataCall() {
      this.fn_CallbackData()
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
  ***********************************************************************************/
  computed: {
    fn_CmptdNowCustTot() {
      let custSelfList = this.lv_NowHoshList.filter(item => {
          return item.mnCustRltnCd === '01'
        });

      return custSelfList ? custSelfList.length : 0
    },
    fn_CmptdMoveCustTot() {
      let custSelfList = this.lv_MoveHoshList.filter(item => {
          return item.mnCustRltnCd === '01'
        });

      return custSelfList ? custSelfList.length : 0
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
  ***********************************************************************************/
  methods: {
    /*********************************************************
     * @function fn_Init
     * @notice   화면진입시, 최초 실행 함수
     *           초기 셋팅 및 설정
    *********************************************************/
    fn_Init () {
      this.lv_RsltBasSVO = this.pCustInfo ? this.pCustInfo.lv_RsltBasSVO : {}
      this.lv_HoshList = this.lv_RsltBasSVO ? this.lv_RsltBasSVO.iCHoshInfoListInqrVO : []

      this.fn_SetCustRltnList()
    },
    /*********************************************************
     * @function fn_SetCustRltnList
     * @notice   주고객관계 - 선택값 셋팅
    *********************************************************/
    fn_SetCustRltnList () {
      this.lv_CustRltnList = [...cmConstants.CUST_RLTN_CD, {key: '08', label: '기타가족'}]

      this.lv_CustRltnList.forEach(item => {
        item.value = item.key
        item.text = item.label
      });

      this.lv_NowHoshList = [this.lv_RsltBasSVO, ...this.lv_HoshList]
    },
    /***********************************************************************************
     * @function fn_HoshHandler
     * @name     세대원-이동-핸들러-함수
     * @notice    현재 세대 -> 이동할 세대 이동시
     *            본인으로 변경하며, 태아는 변경없이 이동한다
     * @param flag: 세대원 위치 변경 Flag
     *             move: 현재 세대 -> 이동할 세대 / now: 이동할 세대 -> 현재 세대
    ***********************************************************************************/
    fn_HoshHandler (flag, pItem) {
      const lv_Vm = this

      if (flag === 'move') { // 현재 세대 -> 이동할 세대
        lv_Vm.lv_NowHoshList = lv_Vm.lv_NowHoshList.filter(item => {
            return pItem !== item
          });

        // 이동시 본인으로 변경(태아 예외)
        if (pItem.custNm !== '태아') {
          pItem.mnCustRltnCd = '01'
        }
        // lv_Vm.lv_MoveHoshList = [...lv_Vm.lv_MoveHoshList, pItem]
        lv_Vm.lv_MoveHoshList = [pItem, ...lv_Vm.lv_MoveHoshList]

        // Auto Focus (2023.05.08 이동할 세대 위로 쌓이게끔하여, 포커싱 제거)
        // this.$nextTick(_ => {
        //   let scrollHeight = document.getElementById("nsList021").clientHeight
        //   document.getElementById("nsList02").scrollTo(0, scrollHeight)
        // })

      } else if (flag === 'now') { // 이동할 세대 -> 현재 세대
        lv_Vm.lv_MoveHoshList = lv_Vm.lv_MoveHoshList.filter(item => {
            return pItem !== item
          });

        lv_Vm.lv_NowHoshList = [...lv_Vm.lv_NowHoshList, pItem]

        // Auto Focus
        this.$nextTick(_ => {
          let scrollHeight = document.getElementById("nsList011").clientHeight
          document.getElementById("nsList01").scrollTo(0, scrollHeight)
        })
        
      }

      this.$emit('autoScroll', flag)
    },
    /*********************************************************
     * @function fn_CallbackData
     * @notice   조정한 세대 정보를 MSPCM304P에 전달한다.
    *********************************************************/
    fn_CallbackData () {
      // 현재 세대 리스트
      this.lv_NowHoshList.forEach(item => {
        item.hoshType = 'now'
      });
      let movBefChnlCstgrId = this.lv_RsltBasSVO.chnlCstgrId

      // 이동할 세대 리스트
      this.lv_MoveHoshList.forEach(item => {
        item.chnlCstgrId = ' '
        item.hoshType = 'move'
      });

      // return Object
      let rtnValu = {
        chnlCustId: this.lv_RsltBasSVO.chnlCustId, // 채널고객ID
        cnsltNo: this.lv_RsltBasSVO.cnsltNo,       // 담당 컨설턴트 사번
        iCHoshCtrlVO: [...this.lv_NowHoshList, ...this.lv_MoveHoshList], // 세대원 리스트
        movAftChnlCstgrId: ' ', // 변경할 채널카테고리ID(단독세대 변경은 빈값으로 처리됨)
        movBefChnlCstgrId: movBefChnlCstgrId // 현재 채널카테고리ID
      } // movBefChnlCstgrId -> movAftChnlCstgrId 으로 변경된다.

      this.$emit('setCM305D', rtnValu)
    }
  } // ::Methods End
};
</script>
