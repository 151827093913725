/*
 * 업무구분 : 고객
 * 화 면 명 : MSPCM304P
 * 화면설명 : 세대조정
 * 작 성 일 : 2023.03.16
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-page-container title="세대조정" :show-title="true" type="subpage" class="msp" @on-header-left-click="fn_HistoryBack">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <ur-box-container alignV="start" componentid="" direction="column">
        <!-- 진행단계 ::Start -->
        <ur-box-container alignV="start" direction="column" class="msp-step-type bd-b-Ty1">
          <div class="ing-content fexTy3">
            <div class="ing-item" :class="{'checked' : procIndex === 1, 'completed' : procIndex > 1}" @click="fn_MoveStep(1)">
              <span class="step">1</span>
              <mo-checkbox size="small"  class="com-check" checked="" />
              <span class="list-step">유형선택</span>
            </div>
            <div class="ing-item" :class="{'checked' : procIndex === 2 && hoshCntlType === '02', 'completed' : procIndex > 2}" ref="step" v-if="hoshCntlType !== '01'" @click="fn_MoveStep(2)">
              <span class="step">2</span>
              <mo-checkbox size="small"  class="com-check" checked=""/>
              <span class="list-step">이동세대선택</span>
            </div>
            <div class="ing-item" :class="{'checked' : (hoshCntlType === '01' && procIndex === 2) || (hoshCntlType === '02' && procIndex === 3)}">
              <span class="step">{{hoshCntlType === '01' ? '2': '3'}}</span>
              <mo-checkbox size="small"  class="com-check" checked="" />
              <span class="list-step">세대조정</span>
            </div>
          </div>
        </ur-box-container>
        <!-- 진행단계 ::End -->
        <ur-box-container>
          <ur-box-container v-if="procIndex < 2" alignV="start" componentid="" direction="column" class="ns-radio-list2">
            <div class="list-item-wrapper">
              <!-- 2023.05.03 : 순서 이동 단독세대 -> 다른세대 -->
              <div class="list-item pt16 pb16" :class="{'on' : hoshCntlType === '02'}">
                <div class="list-item__main" @click="hoshCntlType = '02'">
                  <mo-radio v-model="hoshCntlType" value="02"></mo-radio>
                  <div class="list-item__contents fs17rem">다른 세대로 이동(세대 간 조정)</div>
                </div>
              </div>
              <div class="list-item pt16 pb16" :class="{'on' : hoshCntlType === '01'}">
                <div class="list-item__main" @click="hoshCntlType = '01'">
                  <mo-radio v-model="hoshCntlType" value="01" checked></mo-radio>
                  <div class="list-item__contents fs17rem">단독 세대로 구성(세대 분리)</div>
                </div>
              </div>
            <!--// 2023.05.03 : 순서 이동 단독세대 -> 다른세대 -->
            </div>
          </ur-box-container>
        
          <ur-box-container v-if="procIndex < 2" alignV="start" componentid="" direction="column" class="pa2024 pb0">
            <div class="bgcolor-1 pt16 pb16 pl20 pr20 bd-radius-10">
              <ul class="bullet-type-4 crTy-bk7">
                <li class="mb10">
                  <h6 class="fs14rem fwb mb4">다른세대로 이동</h6>
                  <p class="fs14rem mt0 mb0">현재 세대 내 인원을 다른세대로 이동하거나 다른 세대 인원을 현재 세대 인원으로 추가하는 경우</p>
                </li>
                <li>
                  <h6 class="fs14rem fwb mb4">단독세대로 구성</h6>
                  <p class="fs14rem mt0 mb0">현재 세대 내 인원을 단독 세대로 분리할 경우</p>
                </li>
              </ul>
            </div>
          </ur-box-container>

          <ur-box-container v-else-if="hoshCntlType === '01' && procIndex === 2" alignV="start" componentid="" direction="column" class="pa2024">
            <ul class="bullet-type-4 crTy-bk7">
              <li class="fs14rem mb5">단독세대로 분리할 인원을 ▼▲ 버튼으로 조정하세요.</li>
              <li class="fs14rem">세대 별 ‘본인‘ 은 1명씩 남아있어야 합니다.</li>
            </ul>
          </ur-box-container>

          <ur-box-container v-else-if="hoshCntlType === '02' && procIndex === 3" alignV="start" componentid="" direction="column" class="pa2024">
            <ul class="bullet-type-4 crTy-bk7">
              <li class="fs14rem mb5">단독세대로 분리할 인원을 ▼▲ 버튼으로 조정하세요.</li>
              <li class="fs14rem">세대 별 ‘본인‘ 은 1명씩 남아있어야 합니다.</li>
            </ul>
          </ur-box-container>

        </ur-box-container>
      </ur-box-container>

      <ur-box-container alignV="start" direction="column" class="msp-step-type bd-b-Ty1">
        <!-- 단독세대 구성 -->
        <MSPCM305D v-if="hoshCntlType === '01' && procIndex === 2" :pCustInfo="pCM305D" :getDataCall="call305D" @setCM305D="fn_SetCM305D"/>

        <!-- 다른세대 구성 -->
        <!-- 고객 검색 -->
        <MSPCM307D v-if="hoshCntlType === '02' && procIndex === 2" :pCustInfo="pCM307D" @searchCallback="fn_SrchCallback"/>
        <MSPCM306D v-if="hoshCntlType === '02' && procIndex === 3" :pCustInfo="pCM306D" :getDataCall="call306D" @setCM306D="fn_SetCM306D"/>
      </ur-box-container>
      
      <!-- 하단 버튼 영역 ::Start -->
      <ur-box-container v-if="procIndex < 2" alignV="start" componentid="" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_MoveProc" :disabled="lv_IsBtnDisable">다음</mo-button>
        </div>
      </ur-box-container>
      <ur-box-container v-else-if="(hoshCntlType === '01' && procIndex === 2) || (hoshCntlType === '02' && procIndex === 3)" alignV="start" componentid="" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CancelBtn">취소</mo-button>
          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_CnfmBtn">완료</mo-button>
        </div>
      </ur-box-container>

    </ur-box-container>
  </ur-page-container>
</template>
<script>
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

import MSPCM305D from '@/ui/cm/MSPCM305D' // 단독 세대로 구성
import MSPCM306D from '@/ui/cm/MSPCM306D' // 다른 세대로 구성
import MSPCM307D from '@/ui/cm/MSPCM307D' // 이동세대 선택

/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
***********************************************************************************/
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  // 화면명
  name: "MSPCM304P",
  // 화면ID
  screenId: "MSPCM304P",
  // 컴포넌트 정의
  components: {
    MSPCM305D,
    MSPCM306D,
    MSPCM307D
  },
  /***********************************************************************************
   * 화면 UI Property 정의
  ***********************************************************************************/
  props: {
    pCustInfo: { // 고객 정보
      type: Object,
      default: {}
    },
    pCallViewCd: { // 팝업 호출한 화면 업무 코드
      type: String,
      default: 'CM'
    }
  },

  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
  ***********************************************************************************/
  data() {
    return {
      stndKeyList: [],
      procIndex: 1, // 프로세스 인덱스

      hoshCntlType: '02', // '01': 단독세대, '02': 다른세대 (default: 다른세대 2023.04.24 황고은 선임 요청)
      lv_IsSuccessUpdate: false,
      lv_IsBtnDisable: false,

      lv_CustHoshList: [], // 해당 고객 세대원 리스트
      lv_RsltBasSVO: {},   // 해당 고객 정보

      lv_ChnlCustId: '', // 채널고객ID
      lv_CnsltNo: '', // 담당 컨설턴트 사번

      call305D: false, // 디비너
      call306D: false, // 디비너
      pCM305D:  { // 디비전 전달 Object (MSPCM305D)
        lv_RsltBasSVO: {}
      },
      pCM306D: { // 디비전 전달 Object (MSPCM306D)
        lv_RsltBasSVO: {},
        lv_MoveCustInfo: {}
      },
      pCM307D: { // 디비전 전달 Object (MSPCM307D)
        lv_RsltBasSVO: {}
      }
    };
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    console.log('■■■■■■■ MSPCM304P ■■■■■■■')
  },
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM304P')

    this.fn_Init()
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
  ***********************************************************************************/
  methods: {
    /*********************************************************
     * @function fn_Init
     * @notice   화면진입시, 최초 실행 함수
     *           초기 셋팅 및 설정
    *********************************************************/
    fn_Init() {
      // 다음버튼 활성화 (초기화)
      this.lv_IsBtnDisable = false

      if (this.pCallViewCd === 'CM') { // 고객카드에서 호출하였을때 예외처리 (데이터 바인딩)
        this.pCustInfo.dob = this.$cmUtil.getFullBirthDate(this.pCustInfo.knb, 'yyyyMMdd')
        this.pCustInfo.birthDay = this.$commonUtil.dateDashFormat(this.$bizUtil.addBirthFrontYear(this.pCustInfo.knb.substring(0, 6))) // 생년월일
        this.pCustInfo.sxdsCd = this.pCustInfo.knb.substring(6, 7) // 성별코드
        this.pCustInfo.sxdsNm = this.$cmUtil.getSxdsNmByRrn(this.pCustInfo.knb)

        this.pCM305D.lv_RsltBasSVO = this.$bizUtil.cloneDeep(this.pCustInfo) //Object.assign({}, this.pCustInfo)
        this.pCM306D.lv_RsltBasSVO = this.$bizUtil.cloneDeep(this.pCustInfo) //Object.assign({}, this.pCustInfo)
        this.pCM307D.lv_RsltBasSVO = this.$bizUtil.cloneDeep(this.pCustInfo) //Object.assign({}, this.pCustInfo)
        this.lv_RsltBasSVO = this.$bizUtil.cloneDeep(this.pCustInfo) // Object.assign({}, this.pCustInfo)
      } else {
        if (!_.isEmpty(this.pCustInfo)) {
          if (!this.$bizUtil.isEmpty(this.pCustInfo.chnlCustId)
                && !this.$bizUtil.isEmpty(this.pCustInfo.cnsltNo)) {

            this.lv_ChnlCustId = this.pCustInfo.chnlCustId
            this.lv_CnsltNo = this.pCustInfo.cnsltNo
            this.fn_SelCustCardInfo()
          } else {
            // Toast 알럿
            let toastMsg = '고객의 정보가 없습니다.'
            this.$addSnackbar(toastMsg)

            // 다음버튼 비활성화
            this.lv_IsBtnDisable = true
          }
        }
      }
    },
    /*********************************************************
     * @function fn_HistoryBack
     * @name     뒤로가기_버튼
     * @notice   뒤로가기 버튼 클릭 이벤트
    *********************************************************/
    fn_HistoryBack() {
      switch (this.procIndex) {
        case 1:
          this.fn_CancelBtn() // 해당 팝업 닫기
          break;
        case 2: // 고객명 검색 (MSPCM307D)
          this.pCM305D.lv_RsltBasSVO = this.$bizUtil.cloneDeep(this.lv_RsltBasSVO) // Object.assign({}, this.lv_RsltBasSVO)
          this.procIndex --;
          break;
        case 3: // 단독세대 구성 / 다른세대 구성 (MSPCM305D/MSPCM306D)
          this.pCM306D.lv_RsltBasSVO = this.$bizUtil.cloneDeep(this.lv_RsltBasSVO) // Object.assign({}, this.lv_RsltBasSVO)
          this.pCM307D.lv_RsltBasSVO = this.$bizUtil.cloneDeep(this.lv_RsltBasSVO) // Object.assign({}, this.lv_RsltBasSVO)
          this.procIndex --;
          break;
      }
    },
    /*********************************************************
     * @function fn_MoveProc
     * @name     다음_버튼
     * @notice   다음 버튼 클릭 이벤트
    *********************************************************/
    fn_MoveProc() {
      if (this.procIndex < 3) this.procIndex ++;
    },
    /*********************************************************
     * @function fn_MoveStep
     * @name     가이드라인_버튼
     * @notice   가이드라인 버튼 이벤트
    *********************************************************/
    fn_MoveStep(stepIdx) {
      if (this.procIndex > 2) {
        this.fn_MoveAlert(stepIdx)
      } else {
        this.procIndex = stepIdx
      }
    },
    /*********************************************************
     * @function fn_MoveAlert
     * @name     Step_아이콘_버튼
     * @notice   이동세대선택 아이콘 클릭 이벤트
    *********************************************************/
    fn_MoveAlert(stepIdx) {
      const lv_Vm = this

      // 세대조정에서 클릭시 안내 팝업 호출
      if (this.procIndex < 3) return

      let contMsg = '이전 단계 선택시 수정 중인 내용은 초기화 됩니다. 전 단계로 이동하시겠습니까?'

      let alertObj = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          content: contMsg
        },
        listeners: {
          onPopupConfirm: () => {
            lv_Vm.procIndex = stepIdx
            this.$bottomModal.close(alertObj)
          },
          onPopupClose: () => {
            this.$bottomModal.close(alertObj)
          }
        }
      });

    },
    /*********************************************************
     * @function fn_SrchCallback
     * @name     콜백함수
     * @notice   다른세대 - 고객조회 선택시 콜백함수
    *********************************************************/
    fn_SrchCallback(pData) {
      const lv_Vm = this

      let hoshList = pData.pHoshList

      // 생년월일, 성별코드
      hoshList.forEach(item => {
        item.dob = item.knb ? this.$cmUtil.getFullBirthDate(item.knb, 'yyyyMMdd') : ''
        item.sxdsCd = item.knb ? item.knb.substr(6, 1) : ''
      });

      let pParams = {
        pHoshList: hoshList, // 세대원 리스트
        pChnlCustId: pData.pChnlCustId // 채널고객ID
      }

      this.pCM306D.lv_MoveCustInfo = pParams
      lv_Vm.fn_MoveProc() // 다음 스텝으로 이동
    },

    /*********************************************************
     * @function fn_CancelBtn_/_fn_CnfmBtn
     * @name     취소/완료_버튼
     * @notice   취소/완료 버튼 이벤트
    *********************************************************/
    fn_CancelBtn() {
      this.$emit('onPopupClose')
    },
    fn_CnfmBtn() {
      // 단독세대 - 조정 세대 셋팅 함수 호출(fn_SetCM305D)
      if (this.hoshCntlType === '01' && this.procIndex === 2) {
        this.call305D = !this.call305D

      // 다른세대 - 조정 세대 셋팅 함수 호출(fn_SetCM306D)
      } else if (this.hoshCntlType === '02' && this.procIndex === 3) {
        this.call306D = !this.call306D
      }
    },
    /*********************************************************
     * @function fn_SetCM305D_/_fn_SetCM306D
     * @name     디비젼_데이터_셋팅
     * @notice   단독세대/다른세대 조정한 세대 셋팅
    *********************************************************/
    fn_SetCM305D(pData) {
      this.lv_CustHoshList = pData.iCHoshCtrlVO ? pData.iCHoshCtrlVO : []
      this.fn_CheckHoshList('01', pData) // 01: 단독세대
    },
    fn_SetCM306D(pData) {
      this.lv_CustHoshList = pData.iCHoshCtrlVO ? pData.iCHoshCtrlVO : []
      this.fn_CheckHoshList('02', pData) // 02: 이동세대
    },
    /*********************************************************
     * @function fn_InsListHoshInfo
     * @notice   수정한 세대 정보를 (조정)저장한다
    *********************************************************/
    fn_InsListHoshInfo(params) {
      const lv_Vm = this

      let pParam = params

      const trnstId = 'txTSSCM11I3'
      const auth = 'I'
      this.post(lv_Vm, pParam, trnstId, auth)
        .then(res => {
          console.log(res.body)
          if (!_.isEmpty(res.body)) {
            lv_Vm.lv_IsSuccessUpdate = true

            // 안내 알럿 호출
            let alertMsg = '선택하신 내용으로 세대조정이 완료되었습니다.'
            this.$addSnackbar(alertMsg)

            let rtnObj = {
              custInfo: {
                updtFlag: 2, // 세대관계정보 수정 Flag
                chnlCustId: lv_Vm.lv_RsltBasSVO.chnlCustId, // 채널고객ID
                cnsltNo: lv_Vm.lv_RsltBasSVO.cnsltNo, // 담당컨설턴트사번
              }
            }

            this.$emit('onPopupConfirm', rtnObj)
          }
        })
        .catch(error => {
          window.vue.error(error)
        });

    },
    /*********************************************************
     * @function fn_CheckHoshList
     * @notice   수정한 세대 정보 체크리스트
    *********************************************************/
    fn_CheckHoshList(type, pData) {
      if (_.isEmpty(this.lv_CustHoshList)) return
      let alertMsg = ''
      let selfNowCstgrId = ''
      let selfMoveCstgrId = ''

      let hoshList = this.lv_CustHoshList
      // 현재 세대
      let nowHoshList = hoshList.filter(item => {
          return  item.hoshType === 'now'
        });
        
      // 이동할 세대
      let moveHoshList = hoshList.filter(item => {
          return  item.hoshType === 'move'
        });

      // 현재 세대에 고객이 존재
      if (nowHoshList.length > 0) {
        let nowMnCust = nowHoshList.filter(item => {
              return item.mnCustRltnCd === '01'
          });

        if (!_.isEmpty(nowMnCust)) {
          selfNowCstgrId = nowMnCust[0].chnlCstgrId

          nowHoshList.forEach(item => {
            item.chnlCstgrId = selfNowCstgrId ? selfNowCstgrId : item.chnlCstgrId
          });

        }
      }

      // 이동할 세대에 고객이 존재
      if (moveHoshList.length > 0) {
        let moveMnCust = moveHoshList.filter(item => {
              return item.mnCustRltnCd === '01'
          });

        if (!_.isEmpty(moveMnCust)) {
          selfMoveCstgrId = moveMnCust[0].chnlCstgrId

          moveHoshList.forEach(item => {
            item.chnlCstgrId = selfMoveCstgrId ? selfMoveCstgrId : item.chnlCstgrId
          });

        }
      }

      // 세대에 본인이 없거나, 두명이상 존재 불가
      let slefNowList = nowHoshList.filter(item => {
          return item.mnCustRltnCd === '01'
        });
      let slefMoveList = moveHoshList.filter(item => {
          return item.mnCustRltnCd === '01'
        });

      if (slefNowList.length > 1 || slefMoveList.length > 1) {
        alertMsg = "세대 당 주고객관계 '본인'은 1명씩만 있어야 합니다."
        this.fn_OpenBottomAlert(alertMsg)
        return
      }
      
      // 단독세대/다른세대 이동 동일 (2023.05.12 양방향으로 저장 가능하게 요청 황고은 프로)
      //  => 세대원 세션별로 '본인' 세대원 체크
      if (!_.isEmpty(nowHoshList) && _.isEmpty(slefNowList)) {
        alertMsg = "현재세대에 주고객(본인)과의 관계를 '본인'으로 지정할 세대원을 정해주세요."
        this.fn_OpenBottomAlert(alertMsg)
        return
      }
      if (!_.isEmpty(moveHoshList) && _.isEmpty(slefMoveList)) {
        alertMsg = "이동할 세대에 주고객(본인)과의 관계를 '본인'으로 지정할 세대원을 정해주세요."
        this.fn_OpenBottomAlert(alertMsg)
        return
      }

      // 세대에 배우자 두명이상 존재 불가
      let spuNowList = nowHoshList.filter(item => {
          return item.mnCustRltnCd === '02'
        });
      let spufMoveList = moveHoshList.filter(item => {
          return item.mnCustRltnCd === '02'
        });
      if (spuNowList.length > 1 || spufMoveList.length > 1) {
        alertMsg = "배우자 2명이상으로 저장이 불가능합니다.<br>세대 조정 후 진행하시기 바랍니다."
        this.fn_OpenBottomAlert(alertMsg)
        return
      }
      
      // 세대조정 저장 함수 호출
      this.fn_InsListHoshInfo(pData)
    },
    /*********************************************************
     * @function fn_SelCustCardInfo
     * @notice   고객 상세인적 사항 정보 조회
    *********************************************************/
    fn_SelCustCardInfo() {
      const lv_Vm = this

      let pParam = {
        chnlCustId: this.lv_ChnlCustId, // 채널고객ID
        cnsltNo: this.lv_CnsltNo, // 담당 컨설턴트 사번
        conctScCd: "1",
        whlInqrYn: "Y"
      }
      // GA 매니저 대행 컨설턴트 변경시
      if(this.$cmUtil.fn_GachangeTF()){
        pParam.cnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      }
	  
      //매니저가 컨설턴트를 변경했을경우
      this.stndKeyList = []
      if(this.$cmUtil.fn_GachangeTF()){
        this.stndKeyList.push({stndKeyId: 'cnsltNo', stndKeyVal: this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo})
      }

      const trnstId = 'txTSSCM11S1'
      const auth = 'S'
      // 고객상세인적사항 정보
      this.post(lv_Vm, pParam, trnstId, auth)
        .then(res => {
          if (lv_Vm.$commonUtil.isSuccess(res)) {
            // 서비스 기본 후처리 (서비스 호출 결과(body)가 있을 경우)
            if (res.body !== null && res.body !== '') {
              // 서버 데이터 가져오기
              lv_Vm.lv_RsltBasSVO = res.body

              lv_Vm.lv_RsltBasSVO.birthDay = lv_Vm.$commonUtil.dateDashFormat(lv_Vm.$bizUtil.addBirthFrontYear(lv_Vm.lv_RsltBasSVO.knb.substring(0, 6))) // 생년월일
              lv_Vm.lv_RsltBasSVO.sxdsCd = lv_Vm.lv_RsltBasSVO.knb.substring(6, 7) // 성별코드
              lv_Vm.lv_RsltBasSVO.sxdsNm = lv_Vm.$cmUtil.getSxdsNmByRrn(lv_Vm.lv_RsltBasSVO.knb)

              lv_Vm.lv_RsltBasSVO.iCHoshInfoListInqrVO.forEach(item => {
                item.birthDay = lv_Vm.$commonUtil.dateDashFormat(lv_Vm.$bizUtil.addBirthFrontYear(item.knb.substring(0, 6))) // 생년월일
                item.sxdsCd = item.knb.substring(6, 7) // 성별코드
                item.sxdsNm = lv_Vm.$cmUtil.getSxdsNmByRrn(item.knb)
              });

              lv_Vm.pCM305D.lv_RsltBasSVO = this.$bizUtil.cloneDeep(lv_Vm.lv_RsltBasSVO) //Object.assign({}, this.lv_RsltBasSVO)
              lv_Vm.pCM306D.lv_RsltBasSVO = this.$bizUtil.cloneDeep(lv_Vm.lv_RsltBasSVO) //Object.assign({}, this.lv_RsltBasSVO)
              lv_Vm.pCM307D.lv_RsltBasSVO = this.$bizUtil.cloneDeep(lv_Vm.lv_RsltBasSVO) //Object.assign({}, this.lv_RsltBasSVO)
            } else { // 고객카드 정보가 없거나 변경, 삭제된 경우
              // 대면용을 제외한 업무용 UI 퍼플 색상 적용 요청 (requested by 황고은 선임), modified by anarchi, 2018.12.20
              // lv_Vm.getStore('confirm').dispatch('ADD', '삭제된 고객이거나 해당 고객카드 정보가 없습니다.')
            }
          } else { // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
            // lv_Vm.getStore('confirm').dispatch('ADD', res.msgComm.msgDesc)
          }
        });
        
    },
    /*********************************************************
     * @function fn_OpenBottomAlert
     * @notice   바텀시트 알림 알럿
     *           수정완료 알럿 확인시, 팝업 Close
    *********************************************************/
    fn_OpenBottomAlert(alertMsg) {
      const lv_Vm = this

      let alertObj = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          content: alertMsg
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(alertObj)
          }
        }
      });

    },
  } // ::Methods End
}
</script>
